import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Interested } from '../types';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { getInterestedById } from '@/src/api/endpoints/interested';
import PotentialCustomerDetail from './PotentialCustomerDetail';

export const PotentialCustomerDetailContainer = (props: Props) => {
  const { row } = props;
  const { data: interestedData, isLoading } = useQuery(['getInterested'], () => getInterestedById(row?.id), {
    select: (data: any) => data?.data?.potential_customer,
  });
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsContentLoaded(true);
      }, 1000);
    }
  }, [interestedData]);

  const childProps = {
    ...props,
    interestedData,
    isContentLoaded,
  };

  return <PotentialCustomerDetail {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  row: Interested;
  idSelected: number;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PotentialCustomerDetailContainer.propTypes = propTypes;

export default PotentialCustomerDetailContainer;
