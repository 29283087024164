import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const NotificationsPage = () => {
  const { t, columns, actionColumns } = useColumns();

  return (
    <CustomDataTable
      title={t('notifications')}
      columns={columns}
      customActionColumns={actionColumns}
      resource="notifications"
      pathApi="notifications/v1/notifications"
      listName="notifications"
      canExport
      canSearch
      defaultSortFieldId="date"
      defaultSortAsc
    />
  );
};

export default NotificationsPage;
