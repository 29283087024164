import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import St from './NotificationDetail.styled';

const NotificationDetail = ({
  t,
  lang,
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
  getFormattedDateFullYearEn,
  data,
}: any) => (
  <St.Content component="div" sx={{ marginY: '16px' }}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InfoLabel first={t('notifications_detail_title_label')} second={`${data?.title}`} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InfoLabel
          first={t('notifications_detail_date_label')}
          second={getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, data?.date)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InfoLabel first={t('notifications_detail_recipient_label')} second={data?.recipient?.person?.full_name} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InfoLabel
          first={t('notifications_detail_property_code_label')}
          second={data?.recipient?.residency?.property?.code}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InfoLabel first={t('notifications_detail_email_label')} second={data?.recipient?.person?.email} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InfoLabel first={t('notifications_detail_phone_label')} second={data?.recipient?.person?.phone_number} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InfoLabel first={t('notifications_detail_message_label')} second={' '} />
      </Grid>
      <Grid item xs={12} sm={12} sx={{ margin: '0px', padding: '0px' }}>
        <p style={{ margin: 0, padding: 0, fontSize: '14px' }}>{data?.message}</p>
      </Grid>
    </Grid>
  </St.Content>
);

export default NotificationDetail;
