import { CardContent, CardHeader } from '@mui/material';
import ImageNotFound from '@/src/assets/common/images/image_not_found.jpg';
import { getMonthDayYearValue } from '@/src/utilities/helpers/dateParser';
import { NewsCardWrapper, NewsCardFooterText } from '../styles/CreateBanner.styled';
import { CardAvatar } from './BannerCard.styles';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { Banners } from '../types';
import { getTenant } from '@/src/utilities/helpers/utils';

interface BannerCardProps {
  item: Banners;
  setIdSelected: (id: number) => void;
}

const textReload = (text: string) => {
  const titleReload = text?.length > 45 ? `${text?.slice(0, 45)}...` : text;
  return titleReload;
};

const BannerCard = ({ item, setIdSelected }: BannerCardProps) => {
  const { id, name, start_date: startDate, image_url: coverImageUrl, description } = item;
  const handleClick = (event: any, selectedId: any) => {
    setIdSelected(selectedId);
  };

  return (
    <NewsCardWrapper onClick={(event) => handleClick(event, id)} key={id}>
      <CardHeader
        avatar={<CardAvatar aria-label="card-label">{getTenant()}</CardAvatar>}
        title={textReload(name)}
        titleTypographyProps={{
          fontWeight: 'bold',
          width: '200px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        subheader={getMonthDayYearValue(startDate)}
      />
      <CustomCardMedia key={id} src={coverImageUrl ?? ImageNotFound} alt={name} height="180px" />
      <CardContent>
        <NewsCardFooterText variant="body2">{textReload(description)}</NewsCardFooterText>
      </CardContent>
    </NewsCardWrapper>
  );
};

export default BannerCard;
