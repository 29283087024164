import { useTranslation } from 'react-i18next';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { Checkbox } from '@mui/material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useVehicleBrands from '@/src/hooks/filters/vehicleBrandsModels.hooks';
import useVehicleColors from '@/src/hooks/filters/vehicleColors.hooks';
import useVehicleFuelTypes from '@/src/hooks/filters/vehicleFuelType.hooks';
import { getLanguage } from '@/src/utilities/storage';
import { CarSettingsPage } from './CarSettingsPage';
import CarConciergeModal from './CarSettingsModal';
import { CarRequestType } from '../types';

export const CarSettingsPageContainer = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { apartments } = useApartmentsFilters();
  const { vehicleBrands, vehicleModels } = useVehicleBrands();
  const { colors } = useVehicleColors();
  const { fuelTypes } = useVehicleFuelTypes();

  const actionColumns: ActionColumn[] = [
    {
      id: 'assign_vehicle',
      icon: (props: any) => <AssignmentTurnedInIcon sx={props.sx} />,
      component: (props: any) => <CarConciergeModal {...props} />,
      width: { xs: '60%' },
    },
  ];

  const columns: CustomDataTableColumnsProps<CarRequestType>[] = [
    {
      id: 'id',
      name: t('elevator_page_table_column_id', { ns: 'carSettings' }),
      selector: (row: CarRequestType) => row.vehicle?.id,
      sortField: 'id',
      right: true,
      maxWidth: '1%',
    },
    {
      id: 'apartment',
      sortField: 'residency_property_code',
      name: t('user_detail_apartment', { ns: 'carSettings' }),
      selector: (row) => row?.residency?.property?.code,
      sortable: true,
      center: true,
      filters: apartments,
      width: '10%',
    },
    {
      id: 'resident',
      sortField: 'holder_full_name',
      name: t('resident', { ns: 'carSettings' }),
      selector: (row: CarRequestType) => row?.residency?.holder?.name,
      sortable: true,
      center: true,
      filters: [
        {
          id: 'customer-full-name',
          query: 'HolderFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'vehicle_brand',
      sortField: 'vehicle_brand',
      name: t('vehicle_brand', { ns: 'carSettings' }),
      selector: (row: CarRequestType) => row?.vehicle?.model?.brand?.name,
      sortable: true,
      center: true,
      filters: vehicleBrands,
      width: lang === 'en' ? '6%' : '5%',
    },
    {
      id: 'vehicle_model',
      sortField: 'vehicle_model',
      name: t('vehicle_model', { ns: 'carSettings' }),
      selector: (row: CarRequestType) => row?.vehicle?.model?.name ?? '-',
      sortable: true,
      center: true,
      filters: vehicleModels,
    },
    {
      id: 'vehicle_color',
      sortField: 'vehicle_color',
      name: t('vehicle_color', { ns: 'carSettings' }),
      selector: (row: CarRequestType) => row?.vehicle?.color?.name,
      sortable: true,
      center: true,
      filters: colors,
      width: lang === 'en' ? '8%' : '5%',
    },
    {
      id: 'vehicle_plate',
      sortField: 'vehicle_license_plate',
      name: t('vehicle_plate', { ns: 'carSettings' }),
      selector: (row: CarRequestType) => row?.vehicle?.license_plate,
      sortable: true,
      center: true,
      filters: [
        {
          id: 'vehicle-full-plate',
          query: 'VehicleLicensePlate',
          type: 'contains',
        },
      ],
    },
    {
      id: 'fuel_type_value',
      sortField: 'vehicle_fuel_type',
      name: t('fuel_type_value', { ns: 'carSettings' }),
      selector: (row: CarRequestType) => row?.vehicle?.fuel_type?.name ?? '-',
      sortable: true,
      center: true,
      filters: fuelTypes,
    },
    {
      id: 'parking_space',
      sortField: 'vehicle_fuel_type',
      name: t('car_concierge_form_parking_space_id_field_label', { ns: 'carSettings' }),
      selector: (row: CarRequestType) => row?.metadata.parking_space_id ?? '-',
      sortable: false,
      center: true,
    },
    {
      id: 'is_rented',
      sortField: 'vehicle_is_rented',
      name: t('is_rented', { ns: 'carSettings' }),
      selector: (row: CarRequestType) => (row?.vehicle?.is_rented ? `${t('yes')}` : 'No'),
      cell: (row: CarRequestType) => <Checkbox checked={row?.vehicle?.is_rented} disabled sx={{ padding: '0px' }} />,
      sortable: false,
      center: true,
    },
  ];

  const childProps = {
    columns,
    // headerActions,
    actionColumns,
    t,
  };

  return <CarSettingsPage {...childProps} />;
};

export default CarSettingsPageContainer;
