import { Box, Grid } from '@mui/material';
import React from 'react';
import { NewsFormProps } from '../../types';
import BannerFormInformation from './banner-form-information';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { SubmitButton } from './BannerForm.styled';

const BannerForm = ({
  handleSubmit,
  values,
  setFieldValue,
  setFieldError,
  errors,
  isLoadingSubmit,
  errorMessage,
  t,
  a11yProps,
  disableButton,
  setDisableButton,
}: NewsFormProps) => (
  <Box component="form" onSubmit={handleSubmit} sx={{ mt: '5px' }}>
    <BannerFormInformation
      values={values}
      errors={errors}
      setFieldValue={setFieldValue}
      setFieldError={setFieldError}
      errorMessage={errorMessage}
      isLoadingSubmit={isLoadingSubmit}
      setDisableButton={setDisableButton}
    />
    {errorMessage && (
      <Grid xs={6} item>
        <ErrorText error={errorMessage} />
      </Grid>
    )}
    <Grid container item xs={errorMessage ? 6 : 12} justifyContent="flex-end" mt="20px">
      <SubmitButton loading={isLoadingSubmit} variant="contained" type="submit" disabled={disableButton}>
        {t('post', { ns: 'news' })}
      </SubmitButton>
    </Grid>
  </Box>
);

export default React.memo(BannerForm);
