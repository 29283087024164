import { Autocomplete, Box, Chip, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { FormEvent } from 'react';
import { LoadingButton } from '@mui/lab';
import { FormikErrors, FormikValues } from 'formik';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { Activities } from '@/src/pages/administrators/types';
import CustomInput from '@/src/components/forms/CustomInput';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';

const ScheduleForm = (props: Props) => {
  const {
    t,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    errorMessage,
    propertiesAvailable,
    availablePlaces,
    isLoadingRequest,
  } = props;

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={1} sx={{ mt: '10px' }}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            id="property"
            size="small"
            value={values?.property || null}
            getOptionLabel={(option) => `${option?.property?.code} - ${option?.person?.full_name}`}
            options={propertiesAvailable ?? []}
            onChange={(e, value) => {
              setFieldValue('property', value ?? '');
            }}
            sx={{ backgroundColor: '' }}
            renderTags={(value, getTagProps) =>
              value.map((option: any, index: number) => (
                <Chip
                  label={`${option?.property?.code} - ${option?.person?.full_name}`}
                  {...getTagProps({ index })}
                  key={option.id}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`${t('schedule_form_property_field_label', { ns: 'bookings' })}`}
                placeholder={t('schedule_form_property_field_placeholder', { ns: 'bookings' })}
                error={errors?.property !== undefined}
                helperText={errors?.property?.toString()}
                sx={{
                  input: {
                    WebkitTextFillColor: '',
                  },
                  label: {
                    color: '',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            id="numberPlaces"
            size="small"
            value={values?.numberPlaces || null}
            getOptionLabel={(option) => option.toString()}
            options={availablePlaces ?? []}
            onChange={(e, value) => {
              setFieldValue('numberPlaces', value ?? '');
            }}
            sx={{ backgroundColor: '' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`${t('schedule_form_places_field_label', { ns: 'bookings' })}`}
                placeholder={t('schedule_form_places_field_placeholder', { ns: 'bookings' })}
                error={errors?.numberPlaces !== undefined}
                helperText={errors?.numberPlaces?.toString()}
                sx={{
                  input: {
                    WebkitTextFillColor: '',
                  },
                  label: {
                    color: '',
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ mt: '10px' }}>
        <Grid item xs={12} sm={6}>
          <CustomInput
            field="activity"
            label={t('schedule_form_amenities_list_field_label', { ns: 'bookings' })}
            errors={errors}
            placeholder={t('schedule_form_amenities_list_field_placeholder', { ns: 'bookings' })}
            values={values}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="hour"
            label={t('hour', { ns: 'bookings' })}
            type="time"
            size="small"
            value={values?.hour || ''}
            error={!!errors?.hour}
            helperText={errors?.hour ? 'Invalid hour' : ''}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300,
            }}
            variant="outlined"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      {errorMessage && (
        <Grid item xs={12} sm={8} md={6} sx={{ mt: '10px' }}>
          <ErrorHelper error={errorMessage} />
        </Grid>
      )}

      <Grid container mt={2} sx={{ placeContent: 'flex-end' }}>
        <LoadingButton
          loading={isLoadingRequest}
          variant="contained"
          size="small"
          type="submit"
          sx={{ width: { xs: 'calc(100% - 16px)', lg: 'auto' } }}
        >
          {t('schedule_form_create_button')}
        </LoadingButton>
      </Grid>
    </Box>
  );
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

interface ExtraProps {
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  errors: FormikErrors<FormikValues>;
  errorMessage: { code: string; error_messages: string } | null | undefined;
  propertiesAvailable: any[] | null | undefined;
  amenitiesList: { activities: Array<Activities> | undefined | null; totalCount: number };
  availablePlaces: number[];
  isLoadingRequest: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
ScheduleForm.prototypes = propTypes;

export default ScheduleForm;
