import { Autocomplete, Box, CardMedia, Grid, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { RelativeForm } from './types';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import CustomInput from '@/src/components/forms/CustomInput';
import PhoneSelect from '@/src/components/phone-select/PhoneSelect';
import { CustomDatePicker } from '@/src/components/date-picker/DatePicker';
import { handlerDateSelector } from '@/src/utilities/helpers/utils';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { GridImage } from '@/src/pages/bookings/amenities/abm/create-edit-amenity/CreateEditAmenity.styled';

const ResidentsRelativesForm = ({
  isEdit,
  handleSubmit,
  values,
  setFieldValue,
  errors,
  errorMessage,
  isSubmitLoading,
  isLoadingGeneral,
  countriesData,
  statesData,
  citiesData,
  relationshipType,
  errorSubmittingImage,
  getUrlIn64,
  isLoadingImage,
  t,
}: RelativeForm) => {
  const today = new Date().getTime();

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ marginY: '5px' }}>
      <Grid container spacing={2} pt={1}>
        {isLoadingGeneral || !values ? (
          <CircularSpinner />
        ) : (
          <>
            <Grid item xs={12} sm={4} md={6}>
              <CustomInput
                field="name"
                errors={errors}
                label={t('name')}
                placeholder={t('resident_form_name_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                autoFocus
                maxLength={50}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={6}>
              <CustomInput
                field="surname"
                errors={errors}
                label={t('surname')}
                placeholder={t('resident_form_surname_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                autoFocus
                maxLength={50}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="alias"
                errors={errors}
                label={t('alias')}
                placeholder={t('resident_form_alias_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="email"
                errors={errors}
                label={t('email')}
                placeholder={t('resident_form_email_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={6} justifyContent="flex-end">
              <PhoneSelect values={values} errors={errors} setFieldValue={setFieldValue} />
            </Grid>
            <Grid item xs={12} sm={4} md={6} justifyContent="flex-end">
              <Autocomplete
                id="relationshipType"
                size="small"
                options={relationshipType ?? []}
                onChange={(e: any, value: any) => {
                  setFieldValue('relationshipType', value);
                }}
                value={
                  relationshipType?.find((type: any) => String(type?.id) === String(values?.relationshipType?.id)) ||
                  null
                }
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box component="li" key={option.id} {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${t('resident_family_type_field_label')} *`}
                    placeholder={t('resident_family_type_field_label_placeholder')}
                    error={errors.state !== undefined}
                    helperText={errors.state?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomDatePicker
                value={values?.birthday}
                onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'birthday')}
                maxDate={today}
                fieldValue="birthday"
                placeholder={t('birthday')}
                errorMessage={errors?.birthday}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="country_autocomplete"
                size="small"
                options={countriesData ?? []}
                onChange={(e: any, value: any) => {
                  if (!value) {
                    setFieldValue('state', null);
                    setFieldValue('city', null);
                  }
                  setFieldValue('country', value);
                }}
                value={
                  countriesData?.find((country: any) => String(country.id) === String(values?.country?.id)) || null
                }
                getOptionLabel={(option) => option.name}
                renderOption={(propss, option) => (
                  <Box component="li" key={option.id} {...propss}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${t('resident_country_field_label')} *`}
                    placeholder={t('resident_country_field_label_placeholder')}
                    error={errors.country !== undefined}
                    helperText={errors.country?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="states_autocomplete"
                size="small"
                options={statesData ?? []}
                onChange={(e: any, value: any) => {
                  if (!value) {
                    setFieldValue('city', null);
                  }
                  setFieldValue('state', value);
                }}
                value={statesData?.find((state: any) => String(state.id) === String(values?.state?.id)) || null}
                getOptionLabel={(option) => option.name}
                disabled={!values.country}
                renderOption={(propss, option) => (
                  <Box component="li" key={option.id} {...propss}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${t('resident_state_field_label')} *`}
                    placeholder={t('resident_state_field_label_placeholder')}
                    error={errors.states !== undefined}
                    helperText={errors.states?.toString()}
                    disabled={!values.country}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="city_autocomplete"
                size="small"
                options={citiesData ?? []}
                onChange={(e: any, value: any) => {
                  setFieldValue('city', value);
                }}
                value={citiesData?.find((city: any) => String(city.id) === String(values?.city?.id)) || null}
                getOptionLabel={(option) => option.name}
                disabled={!values.state}
                renderOption={(propss, option) => (
                  <Box component="li" key={option.id} {...propss}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${t('resident_city_field_label')} *`}
                    placeholder={t('resident_city_field_label_placeholder')}
                    error={errors.cityId !== undefined}
                    helperText={errors.city?.toString()}
                    disabled={!values.state}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="streetName"
                errors={errors}
                label={t('street_name')}
                placeholder={t('resident_form_street_name_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="streetNumber"
                errors={errors}
                label={t('street_number')}
                placeholder={t('resident_form_street_number_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                required
                inputType="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="floorNumber"
                errors={errors}
                label={t('floor_number')}
                placeholder={t('resident_form_floor_number_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                required
                inputType="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="apartmentNumber"
                errors={errors}
                label={t('apartment_number')}
                placeholder={t('resident_form_apartment_number_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="zipCode"
                errors={errors}
                label={t('zip_code')}
                placeholder={t('resident_form_zip_code_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                required
                inputType="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                field="identificationNumber"
                errors={errors}
                label={t('identificationNumber')}
                placeholder={t('resident_form_identification_number_field_placeholder')}
                setFieldValue={setFieldValue}
                values={values}
                maxLength={11}
                inputType="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {values.identificationFileUrl ? (
                <Grid
                  item
                  xs={24}
                  sm={12}
                  display="flex"
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: { xs: '5px', sm: 0 },
                  }}
                >
                  <Typography marginBottom="10px">{t('resident_identification_file_url')}</Typography>
                  <Box
                    component="div"
                    sx={{
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <IconButton
                      onClick={() => setFieldValue('identificationFileUrl', null)}
                      sx={{
                        position: 'absolute',
                        top: -15,
                        right: -26,
                      }}
                    >
                      <CancelRoundedIcon />
                    </IconButton>
                    <CardMedia
                      component="img"
                      height={110}
                      image={values.identificationFileUrl}
                      alt="images"
                      sx={{ borderRadius: '10px' }}
                    />
                  </Box>
                </Grid>
              ) : (
                <GridImage
                  item
                  xs={24}
                  sm={12}
                  display="flex"
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    marginBottom: { xs: '5px', sm: 0 },
                  }}
                >
                  <Typography marginBottom="10px">{t('resident_identification_file_url')}</Typography>
                  {isLoadingImage ? (
                    <CircularSpinner />
                  ) : (
                    <InputLabel htmlFor="imageHeader" style={{ margin: 'auto' }}>
                      <IconButton>
                        <InputLabel htmlFor="imageHeader">
                          <input
                            id="imageHeader"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => getUrlIn64(e)}
                            style={{ display: 'none' }}
                            type="file"
                            accept=".jpg, .jpeg, .HEIC, .png, .svg"
                          />
                          <AddPhotoAlternateRoundedIcon fontSize="large" style={{ cursor: 'pointer' }} />
                        </InputLabel>
                      </IconButton>
                    </InputLabel>
                  )}
                  <ErrorText
                    error={(errorSubmittingImage?.code as string) || (errors.identificationFileUrl as string)}
                  />
                </GridImage>
              )}
            </Grid>
            {errorMessage && (
              <Grid item xs={12} sm={8} md={6}>
                <ErrorHelper error={errorMessage} />
              </Grid>
            )}
            <Grid container item xs={12} justifyContent="flex-end">
              <LoadingButton
                loading={isSubmitLoading}
                variant="contained"
                size="small"
                type="submit"
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                {isEdit
                  ? t('form_button_submit_text', { ns: 'users' })
                  : t('form_button_submit_text_create', { ns: 'users' })}
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ResidentsRelativesForm;
