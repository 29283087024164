import TableComponent from './TableComponent';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { Users } from './types';

const TableComponentContainer = (props: Props) => {
  const { tableRows, removeItemFromRows } = props;

  const childProps = {
    tableRows,
    removeItemFromRows,
  };

  return <TableComponent {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  tableRows: Array<Users> | null | undefined;
  removeItemFromRows: (item: any) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
TableComponent.propTypes = propTypes;

export default TableComponentContainer;
