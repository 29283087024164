import { useMemo, useContext } from 'react';
import getEnvVariables from '../utilities/helpers/getEnvVariables';
import EventBackgroundPattern from './common/logos/eventBackgroundPattern.svg';
import pdfIconImage from './common/logos/pdfFile.svg';
import { grit } from './grit';
import { panorama } from './panorama';
import { beachwalk } from './beachwalk';
import { ora } from './ora';
import { nexo } from './nexo';
import { rider } from './rider';
import { AuthContext } from '../context/auth.context';
import { birgeandheld } from './birgeandheld';
import { huergo } from './huergo';

const { VITE_TENANT_NAME } = getEnvVariables();

export const useExportAssets = () => {
  const { clientStyle } = useContext(AuthContext);
  const formatedClientStyle = JSON.parse(clientStyle);

  const objAssets: { [key: string]: any } = useMemo(
    () => ({
      ora,
      grit,
      panorama,
      beachwalk,
      nexo,
      rider,
      birgeandheld,
      huergo,
    }),
    [clientStyle],
  );

  const client = objAssets[VITE_TENANT_NAME];

  const { backgroundPic, mainLogo, whiteLogo, headLogo } = client || {};

  const EventBackgroundPatternImage = EventBackgroundPattern;
  const pdfIcon = pdfIconImage;

  return {
    backgroundPic,
    mainLogo: formatedClientStyle?.main_logo_url ?? mainLogo,
    whiteLogo: formatedClientStyle?.white_logo_url ?? whiteLogo,
    headLogo,
    EventBackgroundPatternImage,
    pdfIcon,
  };
};
