import { axiosAuthenticationInstance } from '../instances/authInstance';
import {
  CreateChargeProps,
  CreateResidentProps,
  EditChargeProps,
  EditPersonRequest,
  EditPersonSelectedRequest,
  FindResidentProps,
  UpdateResidentNoteRequest,
  UpdateValues,
} from '../types/types';
// PADRON - PEOPLE

export const findResident = (req: FindResidentProps) =>
  axiosAuthenticationInstance.get(`master/v1/people/${req.countryCode}/${req.number}`);

export const createsResidentInfo = (req: CreateResidentProps) =>
  axiosAuthenticationInstance.post('master/v1/people', req);

export const personSelected = (id: number) => axiosAuthenticationInstance.get(`master/v1/property-residents/${id}`);

export const editPersonInformation = (req: EditPersonRequest) =>
  axiosAuthenticationInstance.put(`master/v1/people/${req.id}`, req.values);

export const editPersonFound = (req: UpdateValues) =>
  axiosAuthenticationInstance.post(`master/v1/property-residents`, req);

export const editPersonSelected = (req: EditPersonSelectedRequest) =>
  axiosAuthenticationInstance.put(`master/v1/residencies/${req.id}`, req.values);

export const disableInformation = async (id: number) => {
  const response = await axiosAuthenticationInstance.delete(`/master/v1/property-residents/${id} `);
  return response;
};

export const getLastNoteFromResident = (id: number) =>
  axiosAuthenticationInstance.get(`master/v1/people/${id}/last-notes`);

export const editResidentNote = (req: UpdateResidentNoteRequest) =>
  axiosAuthenticationInstance.post(`master/v1/people/${req.id}/person-notes`, req.note);

export const getProperties = async ({ fromDate, toDate }: { fromDate?: number; toDate?: number }) =>
  axiosAuthenticationInstance.get(
    `/master/v1/properties${fromDate ? `?ResidencyDateFrom=${fromDate}` : ''}${
      toDate ? `&ResidencyDateTo=${toDate}` : ''
    }`,
  );

export const getChargeById = (id: number) => axiosAuthenticationInstance.get(`payments/v1/charges/${id}`);

export const createCharge = (req: CreateChargeProps) => axiosAuthenticationInstance.post('payments/v1/charges', req);

export const editCharge = ({ id, values }: EditChargeProps) =>
  axiosAuthenticationInstance.put(`payments/v1/charges/${id}`, values);

export const deleteCharge = (id: number) => axiosAuthenticationInstance.delete(`payments/v1/charges/${id}`);

export const getResidents = (id: number) => axiosAuthenticationInstance.get(`master/v1/residencies/${id}/residents`);

export const getStates = () => axiosAuthenticationInstance.get(`master/v1/residency-states`);
export const getPets = (residencyId: number) =>
  axiosAuthenticationInstance.get(`/pets/v1/pet-residencies?residencyId=${residencyId}`);

export const getRelatives = (residencyId: number) =>
  axiosAuthenticationInstance.get(`families/v1/relative-residencies?residencyId=${residencyId}`);

export const getVehicles = (residencyId: number) =>
  axiosAuthenticationInstance.get(`vehicles/v1/vehicle-residencies?residencyId=${residencyId}`);

export const getPropertyResidents = () => axiosAuthenticationInstance.get(`/master/v1/property-residents`);
