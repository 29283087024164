import { FormikErrors, FormikValues } from 'formik';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import MarketingFormInformation from './MarketingFormInformation';
import useImageUploader from '@/src/components/image-uploader/useImageUploader';

const MarketingFormInformationContainer = (props: Props) => {
  const { tabValue, values, errors, setFieldValue, setFieldError, setDisableButton } = props;

  const deleteImage = (value: any, url?: string) => {
    setFieldValue(
      value,
      Array.isArray(values[`${value}`]) ? values?.[`${value}`].filter((element: any) => element.image_url !== url) : '',
    );
  };

  const attachedImagesLength = values?.publication_image_urls.length;

  const { ImageUploader, wrongImages: wrongCoverImages } = useImageUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'cover_image_url',
    setFieldValue,
    setDisableForm: setDisableButton,
  });
  const { ImageUploader: AttachedImagesUploader, wrongImages: wrongAttachedImages } = useImageUploader({
    htmlFor: 'attached-images-urls',
    values,
    valueName: 'publication_image_urls',
    setFieldValue,
    setDisableForm: setDisableButton,
  });

  const childProps = {
    tabValue,
    values,
    deleteImage,
    ImageUploader,
    wrongCoverImages,
    errors,
    setFieldValue,
    setDisableButton,
    setFieldError,
    AttachedImagesUploader,
    attachedImagesLength,
    wrongAttachedImages,
  };

  return <MarketingFormInformation {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  tabValue: number;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  setFieldError: (field: string, value: string | undefined) => void;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
MarketingFormInformationContainer.propTypes = propTypes;

export default MarketingFormInformationContainer;
