import { Box, Grid, Tab, Tabs } from '@mui/material';
import React from 'react';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { SubmitButton } from './ConstructionForm.styled';
import ConstructionFormInformation from './construction-form-information-tab';
import ConstructionFormAddresse from '@/src/components/addresse';
import { ConstructionFormProps } from '../../types';

const ConstructionForm = ({
  handleSubmit,
  values,
  setFieldValue,
  errors,
  isLoadingSubmit,
  errorMessage,
  disableButton,
  setDisableButton,
  setFieldError,
  t,
  tabValue,
  tableRows,
  setTableRows,
  handleChange,
  a11yProps,
}: ConstructionFormProps) => (
  <Box component="form" onSubmit={handleSubmit} sx={{ mt: '5px' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
        <Tab label={`${t('construction_form_first_tab_title')}`} {...a11yProps(0)} />
        <Tab label={`${t('construction_form_second_tab_title')}`} {...a11yProps(1)} />
      </Tabs>
    </Box>
    <ConstructionFormInformation
      handleSubmit={handleSubmit}
      values={values}
      setFieldError={setFieldError}
      errors={errors}
      errorMessage={errorMessage}
      setFieldValue={setFieldValue}
      setDisableButton={setDisableButton}
      t={t}
      tabValue={tabValue}
    />
    <ConstructionFormAddresse
      tabValue={tabValue}
      values={values}
      errors={errors}
      setFieldValue={setFieldValue}
      tableRows={tableRows}
      setTableRows={setTableRows}
    />
    {errorMessage && (
      <Grid xs={6} item>
        <ErrorText error={errorMessage} />
      </Grid>
    )}
    <Grid container item xs={errorMessage ? 6 : 12} justifyContent="flex-end">
      <SubmitButton loading={isLoadingSubmit} variant="contained" type="submit" disabled={disableButton}>
        {t('post')}
      </SubmitButton>
    </Grid>
  </Box>
);

export default React.memo(ConstructionForm);
