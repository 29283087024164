import * as Yup from 'yup';

export const validate = (t: any) =>
  Yup.lazy(() =>
    Yup.object().shape({
      // publication_image_urls: Yup.string().required(t('required_field', { ns: 'errors' })),
      name: Yup.string()
        .required(t('required_field', { ns: 'errors' }))
        .min(1, t('banner_form_name_field_min_length_validation'))
        .max(100, t('banner_form_name_field_max_length_validation')),
      start_date: Yup.string().required(t('required_field', { ns: 'errors' })),
      end_date: Yup.string().required(t('required_field', { ns: 'errors' })),
      description: Yup.string()
        .required(t('required_field', { ns: 'errors' }))
        .min(1, t('banner_form_description_field_min_length_validation'))
        .max(1000, t('banner_form_description_field_max_length_validation')),
      target_link_url: Yup.string().required(t('required_field', { ns: 'errors' })),
      section: Yup.string().required(t('required_field', { ns: 'errors' })),
      section_order: Yup.string().required(t('required_field', { ns: 'errors' })),
    }),
  );
