import { EventsFormInformationContainerProps } from '../../../types';
import EventsFormInformationTab from './EventsFormInformation';
import useImageUploader from '@/src/components/image-uploader/useImageUploader';

const EventsFormInformationTabContainer = ({
  tabValue,
  values,
  setFieldValue,
  errors,
  setDisableButton,
  setFieldError,
  t,
  categoriesList,
}: EventsFormInformationContainerProps) => {
  const { ImageUploader, wrongImages: wrongCoverImages } = useImageUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'cover_image_url',
    setFieldValue,
    setDisableForm: setDisableButton,
  });
  const { ImageUploader: AttachedImagesUploader, wrongImages: wrongAttachedImages } = useImageUploader({
    htmlFor: 'attached-images-urls',
    values,
    valueName: 'publication_image_urls',
    setFieldValue,
    setDisableForm: setDisableButton,
  });

  const deleteImage = (value: any, url?: string) => {
    setFieldValue(
      value,
      Array.isArray(values[`${value}`])
        ? values?.[`${value}`].filter((element: any) => element?.image_url !== url)
        : '',
    );
  };

  const attachedImagesLength = values?.publication_image_urls?.length;

  const childProps = {
    tabValue,
    categoriesList,
    values,
    setFieldValue,
    errors,
    setDisableButton,
    setFieldError,
    t,
    ImageUploader,
    AttachedImagesUploader,
    wrongCoverImages,
    wrongAttachedImages,
    deleteImage,
    attachedImagesLength,
  };

  return <EventsFormInformationTab {...childProps} />;
};

export default EventsFormInformationTabContainer;
