import { createTheme, ThemeProvider, CssBaseline, PaletteMode, Theme } from '@mui/material';
import React, { useState } from 'react';
import baseLightBeachWalkTheme from './baseLightBeachWalkTheme';
import baseLightPanoramaTheme from './baseLightPanoramaTheme';
import baseLightGritTheme from './baseLightGritTheme';
import baseLightOraTheme from './baseLightOraTheme';
import baseLightNexoTheme from './baseLightNexoTheme';
import baseLightRiderTheme from './baseLightRiderTheme';
import baseDarkTheme from './baseDarkTheme';
import baseLightBirgeandheldTheme from './baseLightBirgeandheldTheme';
import baseLightHuergoTheme from './baseLightHuergoTheme';
import { ColorContext } from './ColorContext';
import GlobalStyle from './GlobalStyles';
import getEnvVariables from '../helpers/getEnvVariables';
import { AuthContext } from '@/src/context/auth.context';

const AppTheme = ({ children }: any) => {
  const [mode, setMode] = useState<PaletteMode>('light');
  const { clientStyle } = React.useContext(AuthContext);

  const { VITE_TENANT_NAME } = getEnvVariables();

  const objTheme: { [key: string]: Theme } = {
    grit: baseLightGritTheme(),
    birgeandheld: baseLightBirgeandheldTheme(),
    panorama: baseLightPanoramaTheme,
    beachwalk: baseLightBeachWalkTheme,
    ora: baseLightOraTheme,
    nexo: baseLightNexoTheme,
    rider: baseLightRiderTheme,
    huergo: baseLightHuergoTheme,
  };

  const theme = React.useMemo(
    () => createTheme(mode === 'light' ? objTheme[VITE_TENANT_NAME] : baseDarkTheme),
    [mode, VITE_TENANT_NAME, clientStyle],
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  return (
    <ColorContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </ColorContext.Provider>
  );
};

export default AppTheme;
