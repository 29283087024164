import { Box, Grid, Tab, Tabs } from '@mui/material';
import React from 'react';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { SubmitButton } from './EventsForm.styled';
import EventsFormInformationTab from './events-form-information-tab';
import EventsFormAddresse from '@/src/components/addresse';
import { EventsFormProps } from '../../types';

const EventsForm = ({
  handleSubmit,
  isLoadingSubmit,
  errorMessage,
  disableButton,
  t,
  tableRows,
  setTableRows,
  handleChange,
  tabValue,
  values,
  errors,
  setFieldValue,
  setFieldError,
  setDisableButton,
  a11yProps,
  categoriesList,
}: EventsFormProps) => (
  <Box component="form" onSubmit={handleSubmit} sx={{ mt: '5px' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
        <Tab label={`${t('events_form_first_tab_title')}`} {...a11yProps(0)} />
        <Tab label={`${t('events_form_second_tab_title')}`} {...a11yProps(1)} />
      </Tabs>
    </Box>
    <EventsFormInformationTab
      tabValue={tabValue}
      values={values}
      setFieldValue={setFieldValue}
      errors={errors}
      setDisableButton={setDisableButton}
      setFieldError={setFieldError}
      t={t}
      categoriesList={categoriesList}
    />
    <EventsFormAddresse
      tabValue={tabValue}
      values={values}
      errors={errors}
      setFieldValue={setFieldValue}
      tableRows={tableRows}
      setTableRows={setTableRows}
    />
    {errorMessage && (
      <Grid xs={6} item>
        <ErrorText error={errorMessage} />
      </Grid>
    )}
    <Grid container item xs={errorMessage ? 6 : 12} justifyContent="flex-end">
      <SubmitButton loading={isLoadingSubmit} variant="contained" type="submit" disabled={disableButton}>
        {t('post')}
      </SubmitButton>
    </Grid>
  </Box>
);

export default React.memo(EventsForm);
