import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EventsForm from './EventsForm';
import { createEvent, editEvent, getEvents } from '@/src/api/endpoints/events';
import { ArticleBody } from '../../types';
import { tRequiredFieldError } from '@/src/utilities/helpers/appConstants';
import { getCategories } from '@/src/api/endpoints/categories';

const actualDate = new Date().getTime();

const EventsFormContainer = ({ dataById, close, refetchDataById, setSnackBarMessage }: any) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [tableRows, setTableRows] = React.useState<any[]>([]);

  const getEventsData = async () => {
    const response = await getEvents();
    return response.data;
  };
  const getCategoriesData = async () => {
    const response = await getCategories();
    return response;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { refetch } = useQuery(['publications'], getEventsData);
  const { data } = useQuery(['events-categories'], getCategoriesData, {
    refetchOnReconnect: false,
  });
  const categoriesList = data?.data?.event_categories;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('events');
  const { mutate } = useMutation(createEvent);
  const { mutate: mutateEdit } = useMutation(editEvent);

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const getInitialValues = () => {
    const hasUsersList = dataById?.targets?.roles.length > 0 || dataById?.targets?.users.length > 0;
    const usersList = dataById?.targets?.roles.length > 0 ? dataById?.targets?.roles : dataById?.targets?.users;
    const userType = dataById?.targets?.roles.length > 0 ? 'group' : 'user';

    return {
      category_id: dataById?.category?.id ?? '',
      title: dataById?.title ?? '',
      subtitle: dataById?.subtitle ?? '',
      start_date: dataById?.date ?? actualDate,
      document: dataById?.document_url ?? null,
      video: dataById?.video_url ?? null,
      video_url_64: null,
      cover_image_url: dataById?.cover_image_url ?? '',
      body: dataById?.body ?? '',
      publication_image_urls: dataById?.publication_images ?? [],
      isEdit: !!dataById,
      editId: dataById?.id ?? null,
      userSelectedList: hasUsersList ? usersList : [],
      userListTargetType: hasUsersList ? userType : 'group',
    };
  };

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        category_id: Yup.number().required(tRequiredFieldError),
        title: Yup.string().required(tRequiredFieldError),
        subtitle: Yup.string().required(tRequiredFieldError),
        body: Yup.string().required(tRequiredFieldError),
        cover_image_url: Yup.string().required(tRequiredFieldError),
      }),
    );

  const onSubmit = (onSubmitdata: any) => {
    setIsLoading(true);
    const imageUrls: any = [];
    onSubmitdata.publication_image_urls.map((imageUrl: { image_url: string }) => imageUrls.push(imageUrl.image_url));

    const rolesList: string[] =
      onSubmitdata?.userListTargetType === 'group' ? tableRows?.map((rol: { value: string }) => rol?.value) : [];
    const usersList: number[] =
      onSubmitdata?.userListTargetType !== 'group' ? tableRows?.map((user: { id: number }) => user?.id) : [];

    const body: ArticleBody = {
      category_id: onSubmitdata.category_id,
      title: onSubmitdata.title,
      subtitle: onSubmitdata.subtitle,
      start_date: onSubmitdata.start_date,
      document_url: onSubmitdata.document,
      video_url: onSubmitdata.video || '',
      cover_image_url: onSubmitdata.cover_image_url,
      body: onSubmitdata.body,
      event_image_urls: imageUrls,
      targets: { roles: rolesList, users: usersList },
    };

    if (!onSubmitdata.isEdit) {
      mutate(body, {
        onSuccess: async () => {
          refetch();
          setSnackBarMessage(t('successful_publication_creation_snackbar_text'));
          close();
        },
        onError: async () => {
          setErrorMessage(t('failed_publication_creation_snackbar_text'));
        },
        onSettled: async () => {
          setIsLoading(false);
        },
      });
    } else {
      mutateEdit(
        { id: dataById.id, body },
        {
          onSuccess: async () => {
            setIsLoading(false);
            refetch();
            refetchDataById(dataById.id);
            setSnackBarMessage(t('successful_publication_edition_snackbar_text'));
            close();
          },
          onError: async () => {
            setIsLoading(false);
            setErrorMessage(t('failed_publication_edition_snackbar_text'));
          },
        },
      );
    }
  };

  const { handleSubmit, values, setFieldValue, errors, setFieldError } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const childProps = {
    handleSubmit,
    isLoadingSubmit: isLoading,
    errorMessage,
    disableButton,
    t,
    tableRows,
    setTableRows,
    handleChange,
    tabValue,
    values,
    errors,
    setFieldValue,
    setFieldError,
    setDisableButton,
    a11yProps,
    categoriesList,
  };

  return <EventsForm {...childProps} />;
};

export default EventsFormContainer;
