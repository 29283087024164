import { styled } from '@mui/material/styles';

import { Box, IconButton } from '@mui/material';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';

export const AttachedImagesContainer = styled(Box)(({ theme, width }) =>
  theme.unstable_sx({
    borderRadius: '20px',
    position: 'relative',
    marginBottom: '10px',
    width,
  }),
);

export const AttachedImageUploaderContainer = styled(Box)(({ theme, justifyContent }) =>
  theme.unstable_sx({
    width: '90%',
    display: 'flex',
    justifyContent,
    gap: '10px',
    // flexDirection: { xs: 'column', xl: 'row', lg: 'row', sm: 'row' },
  }),
);

export const DeleteAttachedImageIcon = styled(IconButton)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: -15,
    right: -1,
  }),
);

export const CoverImageContainer = styled(LoaderContainer)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '200px',
  maxHeight: '200px',
  border: '1px dotted gray',
  alignItems: 'center',
  borderRadius: '5px',
  marginLeft: '10px',
  padding: '10px',
}));

export const AttachedLoadedImagesContainer = styled(LoaderContainer)(() => ({
  height: '150px',
  border: 'none',
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: '5px',
}));
