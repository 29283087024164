import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import ScheduleForm from './ScheduleForm';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { getProperties, getPropertyResidents } from '@/src/api/endpoints/residents';
import { PropertyType } from '@/src/pages/household/vehicles/types';
import { createReservation, getAmenities } from '@/src/api/endpoints/bookings';
import { Activities } from '@/src/pages/administrators/types';
import { errorHandlerHelper } from '@/src/utilities/helpers/errorHandlerHelper';

const getValidationSchema = (t: any) =>
  Yup.lazy(() =>
    Yup.object().shape({
      activity: Yup.string(),
      hour: Yup.string(),
      property: Yup.object().required(t('required_field', { ns: 'errors' })),
      numberPlaces: Yup.number().required(t('required_field', { ns: 'errors' })),
    }),
  );

const getInitialValues = (row: any) => ({
  activity: row?.agenda?.name ?? '',
  hour: `${new Date(row?.from).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`,
  property: '',
  numberPlaces: '',
});

const ScheduleFormContainer = (props: Props) => {
  const { close, setSnackBarMessage, row } = props;
  const { t } = useTranslation('bookings');

  const { data: properties } = useQuery(['getPropertyResidents'], () => getPropertyResidents());
  const propertiesAvailable = properties?.data?.property_residents;

  const [errorMessage, setErrorMessage] = useState<{ code: string; error_messages: string } | null | undefined>(null);

  const availablePlaces = Array.from(
    { length: row?.remaining_places },
    (_, index) => Number(row?.remaining_places) - index,
  ).sort((a, b) => a - b);

  const { data: response } = useQuery(['getProperties'], () => getProperties({}));
  const propertiesList: Array<PropertyType> | undefined | null = response?.data.properties;

  const { data: amenitiesData } = useQuery(['activities'], () => getAmenities({ page: 0 }));

  const amenitiesList: { activities: Array<Activities> | undefined | null; totalCount: number } = amenitiesData?.data;

  const { mutate: createRequest, isLoading: isLoadingRequest } = useMutation(createReservation, {
    onSuccess: () => {
      setSnackBarMessage(t('schedule_form_create_success'));
      close();
    },
    onError: async (err: any) => {
      setSnackBarMessage(t('schedule_form_create_error'), 'error');
      setErrorMessage(errorHandlerHelper(err));
    },
  });

  const hourFormatter = (hour: number) => {
    const newDate = new Date(hour);

    const hoursUTC = newDate.getUTCHours();
    const minutesUTC = newDate.getUTCMinutes();
    const secondsUTC = newDate.getUTCSeconds();

    return `${hoursUTC.toString().padStart(2, '0')}:${minutesUTC.toString().padStart(2, '0')}:${secondsUTC
      .toString()
      .padStart(2, '0')}`;
  };

  const onSubmit = (data: FormikValues) => {
    const date = new Date(row?.from);
    date.setHours(0, 0, 0, 0);
    const formatedDate = date.getTime();

    const request = {
      schedule_id: row?.id,
      description: row?.agenda?.name,
      date: formatedDate,
      places: data?.numberPlaces,
      from: hourFormatter(row?.from),
      to: hourFormatter(row?.to),
      property_resident_id: data?.property?.id,
    };
    createRequest(request);
  };

  const { handleSubmit, values, setFieldValue, errors, resetForm } = useFormik({
    initialValues: getInitialValues(row),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(t),
  });

  const childProps = {
    t,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    errorMessage,
    propertiesList,
    amenitiesList,
    availablePlaces,
    isLoadingRequest,
    propertiesAvailable,
  };

  return <ScheduleForm {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  close: () => void;
  setSnackBarMessage: (msj: string, sever?: string) => void;
  row: any;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
ScheduleFormContainer.propTypes = propTypes;

export default ScheduleFormContainer;
