import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateGuestRequest } from '../types/types';

// get
export const invitationDetail = (id: number) => axiosAuthenticationInstance.get(`/guests/v1/guests/${id}`);
export const getInvitationStatus = () => axiosAuthenticationInstance.get(`/guests/v1/invitation-status`);
// post
export const createGuest = (req: CreateGuestRequest) =>
  axiosAuthenticationInstance.post(`/guests/v1/invitations/${req.invitationCode}/guests`, req.data);
// put
export const confirmGuest = (id: number) => axiosAuthenticationInstance.put(`/guests/v1/guests/${id}/admission`);
