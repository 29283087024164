import { useTranslation } from 'react-i18next';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { getFormattedDateByLanguague } from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { Schedule } from './types';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import ScheduleForm from './schedule-modal/schedule-form';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];

  const getFormattedDateTimeUTC = (date: number, locale: string, includeDate = true) => {
    const options = includeDate
      ? { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' }
      : { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };

    return `${new Intl.DateTimeFormat(locale, options as any).format(date)}hs`;
  };

  const getFormattedUTCDateTimeEs = (date: number) => getFormattedDateTimeUTC(date, 'es-ES', true);

  const getFormattedUTCTimeEs = (date: number) => getFormattedDateTimeUTC(date, 'es-ES', false);

  const getFormattedUTCDateTimeEn = (date: number) => getFormattedDateTimeUTC(date, 'en-US', true);

  const getFormattedUTCTimeEn = (date: number) => getFormattedDateTimeUTC(date, 'en-US', false);

  const columns: CustomDataTableColumnsProps<Schedule>[] = [
    {
      id: 'name',
      name: t('schedules_list_activity_column', { ns: 'bookings' }),
      selector: (row: Schedule) => row.agenda.name,
      sortField: 'name',
      sortable: false,
    },
    {
      id: 'from_to',
      name: t('schedules_list_datetime_column', { ns: 'bookings' }),
      selector: (row: Schedule) => {
        const formattedFrom = getFormattedDateByLanguague(
          lang,
          getFormattedUTCDateTimeEs,
          getFormattedUTCDateTimeEn,
          row.from,
        );
        const formattedTo = getFormattedDateByLanguague(lang, getFormattedUTCTimeEs, getFormattedUTCTimeEn, row.to);
        return `${formattedFrom} - ${formattedTo}`;
      },
      sortField: 'from_to',
      sortable: false,
      center: true,
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'create_reservation',
      icon: (props: any) => <InsertInvitationIcon sx={props.sx} />,
      component: (props: any) => <ScheduleForm {...props} />,
    },
  ];

  return { columns, actionColumns };
};
