import { CreateVehicleRequest, EditVehicleRequest, DeleteVehicleRequest } from '@/src/pages/household/vehicles/types';
import { axiosAuthenticationInstance } from '../instances/authInstance';
import { FamilyTypeCreate, FamilyTypeEdit } from '@/src/pages/residents/residents-detail-section/relative-form/types';

// ==================== USERS ====================

// GET
export const getUserById = (id: number) => axiosAuthenticationInstance.get(`/accounts/v1/customers/${id}/people`);

export const getFamilyByResidencyId = (relativeId: number, residencyId: number) =>
  axiosAuthenticationInstance.get(`/families/v1/relative-residencies/${relativeId}/${residencyId}`);

export const getFamilyTypes = () => axiosAuthenticationInstance.get(`/families/v1/relationship-types`);

// PUT
export const approveFamily = (id: number) => axiosAuthenticationInstance.put(`/families/v1/relatives/${id}/approval`);

export const rejectFamily = ({ id, note }: { id: number; note: string }) =>
  axiosAuthenticationInstance.put(`/families/v1/relatives/${id}/rejection`, { rejection_reason: note });

// POST
export const createFamily = (body: FamilyTypeCreate) =>
  axiosAuthenticationInstance.post(`/families/v1/relatives`, body);

// PUT
export const editFamily = ({ id, body }: { id: number; body: FamilyTypeEdit }) =>
  axiosAuthenticationInstance.put(`/families/v1/relatives/${id}`, body);

// DELETE
export const deleteResidencyRelative = ({ relativeId, residencyId }: { relativeId: number; residencyId: number }) =>
  axiosAuthenticationInstance.delete(`/families/v1/relative-residencies/${relativeId}/${residencyId}`);

// ==================== PETS ====================

// GET
export const getPet = ({ id, residencyId }: { id: number; residencyId: number }) =>
  axiosAuthenticationInstance.get(`/pets/v1/pet-residencies/${id}/${residencyId}`);

export const getPetSexes = () => axiosAuthenticationInstance.get(`/pets/v1/sexes`);

export const getPetTypes = () => axiosAuthenticationInstance.get(`/pets/v1/types`);

export const getAllPetBreeds = () => axiosAuthenticationInstance.get(`/pets/v1/breeds`);

export const getPetBreeds = (typeId: string) => axiosAuthenticationInstance.get(`/pets/v1/types/${typeId}/breeds`);

// PUT
export const approvePet = (id: number) => axiosAuthenticationInstance.put(`/pets/v1/pets/${id}/approval`);

export const rejectPet = ({ id, note }: { id: number; note: string }) =>
  axiosAuthenticationInstance.put(`/pets/v1/pets/${id}/rejection`, { rejection_reason: note });

// POST
export const createPet = (body: any) => axiosAuthenticationInstance.post(`/pets/v1/pets`, body);

// PUT
export const editPet = ({ id, body }: any) => axiosAuthenticationInstance.put(`/pets/v1/pets/${id}`, body);

// DELETE
export const deletePet = ({ petId, residencyId }: { petId: number; residencyId: number }) =>
  axiosAuthenticationInstance.delete(`/pets/v1/pet-residencies/${petId}/${residencyId}`);

// ==================== VEHICLES ====================

// GET
export const getVehiclesByUser = (id: number) =>
  axiosAuthenticationInstance.get(`/vehicles/v1/customers/${id}/vehicles?page=${0}&pageSize=${10}&orderField=${1}`);

export const getVehicle = ({ id, residencyId }: { id: number; residencyId: number }) =>
  axiosAuthenticationInstance.get(`/vehicles/v1/vehicle-residencies/${id}/${residencyId}`);

export const getBrands = () => axiosAuthenticationInstance.get(`/vehicles/v1/brands`);

export const getModels = (id: number) => axiosAuthenticationInstance.get(`/vehicles/v1/brands/${id}/models`);

export const getColors = () => axiosAuthenticationInstance.get(`/vehicles/v1/colors`);

export const getFuelTypes = () => axiosAuthenticationInstance.get(`/vehicles/v1/fuel-types`);

export const getStates = () => axiosAuthenticationInstance.get(`master/v1/vehicle-states`);

export const getAllModels = () => axiosAuthenticationInstance.get(`vehicles/v1/models`);

// PUT
export const approveVehicle = (id: number) => axiosAuthenticationInstance.put(`/vehicles/v1/vehicles/${id}/approval`);

export const rejectVehicle = ({ id, note }: { id: number; note: string }) =>
  axiosAuthenticationInstance.put(`/vehicles/v1/vehicles/${id}/rejection`, { rejection_reason: note });

// POST
export const createVehicle = (req: CreateVehicleRequest) =>
  axiosAuthenticationInstance.post(`/vehicles/v1/vehicles`, req);

// PUT
export const editVehicle = (req: EditVehicleRequest) =>
  axiosAuthenticationInstance.put(`/vehicles/v1/vehicles/${req.id}`, req.body);

// DELETE
export const deleteVehicle = async (body: DeleteVehicleRequest) => {
  const response = await axiosAuthenticationInstance.delete(
    `/vehicles/v1/vehicle-residencies/${body?.vehicleId}/${body?.residencyId}`,
  );
  return response;
};
