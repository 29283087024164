import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

import GothamBold from '../../assets/fonts/Gotham/Gotham-Bold.otf';
import GothamBookItalic from '../../assets/fonts/Gotham/Gotham-BookItalic.otf';
import GothamsLight from '../../assets/fonts/Gotham/Gotham-Light.otf';
import GothamMedium from '../../assets/fonts/Gotham/GothamMedium.ttf';
import GTAmericaBold from '../../assets/fonts/GT-America/GT-America-Compressed-Bold.otf';
import GTAmericaMedium from '../../assets/fonts/GT-America/GT-America-Medium.otf';
import GTAmericaRegular from '../../assets/fonts/GT-America/GT-America-Regular.otf';

const baseTheme = createTheme({
  palette: {
    error: {
      main: red.A400,
    },
    mode: 'light',
    primary: {
      main: '#000000',
      // main: 'rgb(0, 20, 65,0.76)',
      // main: '#7367f0',
    },
    secondary: {
      main: '#80E0A7',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none',
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "Gotham";
        font-style: italic;
        font-weight: 400;
        font-display:"swap";
        src: url("${GothamBookItalic}") format("truetype");
      }
      @font-face {
        font-family: "Gotham";
        font-style: italic;
        font-weight: 200;
        font-display:"swap";
        src: url("${GothamBookItalic}") format("truetype");
      }
      @font-face {
        font-family: "Gotham;
        font-style: normal;
        font-weight: 500;
        font-display:"swap";
        src: url("${GothamsLight}") format("truetype");
      }
      @font-face {
        font-family: "Gotham;
        font-style: normal;
        font-weight: 500;
        font-display:"swap";
        src: url("${GothamMedium}") format("truetype");
      }
      @font-face {
        font-family: "GT-America";
        font-style: normal;
        font-weight: 500;
        font-display:"swap";
        src: url("${GTAmericaMedium}") format("truetype");
      }
      @font-face {
        font-family: "GT-America";
        font-style: normal;
        font-weight: 400;
        font-display:"swap";
        src: url("${GTAmericaRegular}") format("truetype");
      }
       @font-face {
        font-family: "GT-America";
        font-style: normal;
        font-weight: 600;
        font-display:"swap";
        src: url("${GTAmericaBold}") format("truetype");
      }
      @font-face {
        font-family: "GT-America";
        font-style: normal;
        font-weight: 700;
        font-display:"swap";
        src: url("${GTAmericaBold}") format("truetype");
      }  
      @font-face {
        font-family: "Gotham";
        font-style: normal;
        font-weight: 600;
        font-display:"swap";
        src: url("${GothamBold}") format("truetype");
      }
      @font-face {
        font-family: "Gotham";
        font-style: normal;
        font-weight: 700;
        font-display:"swap";
        src: url("${GothamBold}") format("truetype");
      }
      `,
    },
  },
  typography: {
    fontFamily: ['AvenirNext', 'Roboto', 'Helvetica', 'Arial', 'serif'].join(','),
  },
});

export default baseTheme;
