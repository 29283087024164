import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { AssignVehicle } from '@/src/api/endpoints/iot';
import CarConciergeModal from './CarSettingsModal';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

const getInitialValues = (id: number) => ({
  parking_space_id: id || '',
});

const CarSettingsModalContainer = (props: Props) => {
  const { row, close, setSnackBarMessage } = props;
  const { t } = useTranslation('carSettings');

  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );

  const { mutate: mutateCarConcierge, isLoading } = useMutation(AssignVehicle, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_vehicle_assignment_snackbar_text'));
    },
  });

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        parking_space_id: Yup.number(),
      }),
    );

  const onSubmit = (data: FormikValues) => {
    const body: any = {
      vehicle_id: row?.vehicle?.id,
      residency_id: row?.residency?.id,
      body: {
        parkingSpaceId: data.parking_space_id,
      },
    };
    mutateCarConcierge(body);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(row?.metadata?.parking_space_id),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const childProps = {
    t,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    errorMessage,
    isSubmitLoading: isLoading,
  };

  return <CarConciergeModal {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  row: any;
  close: () => void;
  setSnackBarMessage: (msj: string, sever?: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CarSettingsModalContainer.propTypes = propTypes;

export default CarSettingsModalContainer;
