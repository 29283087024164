import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateBannerRequest, EditBannerRequest } from '../types/types';

// get
export const getBanners = () => axiosAuthenticationInstance.get(`feed/v1/banners?page=0&pageSize=1000`);
// post
export const createBanner = (req: CreateBannerRequest) => axiosAuthenticationInstance.post('feed/v1/banners', req);
// put
export const editBanner = (req: any) => axiosAuthenticationInstance.put(`feed/v1/banners/${req?.id}`, req?.body);
// delete
export const deleteBanner = (id: number) => axiosAuthenticationInstance.delete(`feed/v1/banners/${id}`);
