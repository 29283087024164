import useImageUploader from '@/src/components/image-uploader/useImageUploader';
import PerksInformationTab from './PerksInformationTab';
import { PerksInformationContainerProps } from '../../../types';

const PerksInformationTabContainer = ({
  values,
  setFieldValue,
  setDisableButton,
  setFieldError,
  errors,
  t,
  tabValue,
}: PerksInformationContainerProps) => {
  const deleteImage = (value: any, url?: string) => {
    setFieldValue(
      value,
      Array.isArray(values[`${value}`]) ? values?.[`${value}`].filter((element: any) => element.image_url !== url) : '',
    );
  };

  const attachedImagesLength = values?.publication_image_urls.length;

  const { ImageUploader, wrongImages: wrongCoverImages } = useImageUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'cover_image_url',
    setFieldValue,
    setDisableForm: setDisableButton,
  });

  const { ImageUploader: AttachedImagesUploader, wrongImages: wrongAttachedImages } = useImageUploader({
    htmlFor: 'attached-images-urls',
    values,
    valueName: 'publication_image_urls',
    setFieldValue,
    setDisableForm: setDisableButton,
  });

  const childProps = {
    values,
    attachedImagesLength,
    setFieldValue,
    setFieldError,
    errors,
    setDisableButton,
    t,
    tabValue,
    ImageUploader,
    wrongCoverImages,
    AttachedImagesUploader,
    wrongAttachedImages,
    deleteImage,
  };

  return <PerksInformationTab {...childProps} />;
};

export default PerksInformationTabContainer;
