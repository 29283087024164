import { FormikErrors, FormikValues } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { UsersList } from './types';
import { getUsers } from '@/src/api/endpoints/users';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';
import AddresseeTab from './FormAddressee';

const AddresseTabContainer = (props: Props) => {
  const { tabValue, values, setFieldValue, errors, tableRows, setTableRows } = props;

  const { VITE_TENANT_NAME } = getEnvVariables();
  const { t } = useTranslation();

  const groupOptionsAvailable = [
    { id: 3, description: `${t('customers')}`, value: 'Customer' },
    { id: 4, description: `${t('potential_customers')}`, value: 'PotentialCustomer' },
  ];

  const getGroupOptionsAvailable = () => {
    const groupOptions = ['nexo', 'ora', 'rider', 'huergo'].includes(VITE_TENANT_NAME)
      ? groupOptionsAvailable
      : [groupOptionsAvailable[0]];

    return groupOptions;
  };

  const [groupList, setGroupList] = useState(getGroupOptionsAvailable());

  const { data: usersData, isLoading } = useQuery(['getUsers'], getUsers, {
    select: (response: any) => response?.data.users as Array<UsersList>,
  });

  const preLoadRowsUsers = (val: FormikValues, tableSetter: Dispatch<SetStateAction<any[]>>) => {
    const rowUsers = usersData?.filter((user: any) => values?.userSelectedList.includes(user.id));
    if (rowUsers && rowUsers?.length > 0) {
      tableSetter(rowUsers);
    }
  };

  const preLoadRowsGroups = (val: FormikValues, tableSetter: Dispatch<SetStateAction<any[]>>) => {
    const groupToAdd = val?.userSelectedList?.map((group: { code: string; id: number }) =>
      groupOptionsAvailable?.find((g: any) => g?.id === group.id),
    );
    if (groupToAdd.length > 0) {
      tableSetter(groupToAdd);
    }
  };

  useEffect(() => {
    if (usersData === undefined) return;

    if (values?.userListTargetType === 'user' && values?.isEdit) {
      const filteredUsers = usersData.filter((user: any) => values?.userSelectedList.includes(user.id));
      setFieldValue('userSelectedList', filteredUsers);
    }

    if (values?.userListTargetType === 'user') {
      preLoadRowsUsers(values, setTableRows);
    } else {
      preLoadRowsGroups(values, setTableRows);
    }
  }, [usersData]);

  const reInitializeList = (type: string, setter: any) => {
    type === 'group' ? setter(getGroupOptionsAvailable()) : setter(usersData);
  };

  const deleteFromList = (list: any[], item: any) => list?.filter((i: any) => i?.id !== item?.id);

  const deselectUser = () => {
    setFieldValue('selectedUser', '');
  };

  const addToTable = () => {
    setTableRows((prev: any) => [...prev, values?.selectedUser]);
    const actualizedList = values?.userSelectedList;
    actualizedList?.push(values?.selectedUser);
    deselectUser();
    setFieldValue('userSelectedList', actualizedList);
  };

  const deselectItem = (type: string) => {
    setFieldValue('userListTargetType', type);
    deselectUser();
    reInitializeList(type, setGroupList);
    setTableRows([]);
    setFieldValue('userSelectedList', []);
  };

  const removeItemFromRows = (item: any) => {
    setTableRows(deleteFromList(tableRows, item));
    const updatedUserSelectedList = values?.userSelectedList?.filter(
      (selectedItem: any) => selectedItem.id !== item.id,
    );
    deselectUser();
    setFieldValue('userSelectedList', updatedUserSelectedList);
  };

  const childProps = {
    t,
    tabValue,
    values,
    setFieldValue,
    deselectItem,
    groupList,
    errors,
    addToTable,
    setGroupList,
    tableRows,
    setTableRows,
    removeItemFromRows,
    usersData,
    isLoading,
  };

  return <AddresseeTab {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  tabValue: number;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  errors: FormikErrors<FormikValues>;
  tableRows: any[];
  setTableRows: React.Dispatch<React.SetStateAction<any[]>>;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
AddresseTabContainer.propTypes = propTypes;

export default AddresseTabContainer;
