import { Box, Grid, Tabs, Tab } from '@mui/material';
import React from 'react';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { SubmitButton } from './PerksBenefitsForm.styled';
import PerksInformationTab from './PerksInformationTab';
import PerksFormAddressee from '@/src/components/addresse';
import { PerksBenefitsFormProps } from '../../types';

const PerksBenefitsForm = ({
  handleSubmit,
  values,
  setFieldValue,
  setFieldError,
  errors,
  isLoadingSubmit,
  errorMessage,
  disableButton,
  setDisableButton,
  t,
  tabValue,
  tableRows,
  setTableRows,
  handleChange,
  a11yProps,
}: PerksBenefitsFormProps) => (
  <Box component="form" onSubmit={handleSubmit} sx={{ mt: '5px' }}>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
        <Tab label={`${t('perks_form_first_tab_title')}`} {...a11yProps(0)} />
        <Tab label={`${t('perks_form_second_tab_title')}`} {...a11yProps(1)} />
      </Tabs>
    </Box>
    <PerksInformationTab
      values={values}
      setFieldValue={setFieldValue}
      setDisableButton={setDisableButton}
      setFieldError={setFieldError}
      errors={errors}
      t={t}
      tabValue={tabValue}
    />
    <PerksFormAddressee
      tabValue={tabValue}
      values={values}
      errors={errors}
      setFieldValue={setFieldValue}
      tableRows={tableRows}
      setTableRows={setTableRows}
    />
    {errorMessage && (
      <Grid xs={6} item>
        <ErrorText error={errorMessage} />
      </Grid>
    )}
    <Grid container item xs={errorMessage ? 6 : 12} justifyContent="flex-end">
      <SubmitButton loading={isLoadingSubmit} variant="contained" type="submit" disabled={disableButton}>
        {t('post')}
      </SubmitButton>
    </Grid>
  </Box>
);

export default React.memo(PerksBenefitsForm);
