import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Filters from './filters/Filters';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';
import { QueryProps } from '@/src/utilities/helpers/commonTypes';

export const SchedulePage = () => {
  const [showTable, setShowTable] = useState(false);
  const [activityId, setActivityId] = useState<number | null>(null);
  const [extraQueryFilters, setExtraQueryFilters] = useState<Array<QueryProps>>([{ query: '', queryValue: '' }]);
  const { t } = useTranslation();
  const { columns, actionColumns } = useColumns();

  const filterProps = {
    showTable,
    setShowTable,
    setActivityId,
    setExtraQueryFilters,
  };

  return (
    <>
      <Filters {...filterProps} />
      {showTable && (
        <CustomDataTable
          title={t('schedule_table_title', { ns: 'bookings' })}
          columns={columns}
          customActionColumns={actionColumns}
          extraFilters={extraQueryFilters}
          resource="schedules"
          pathApi={`bookings/v1/activities/${activityId}/schedules`}
          listName="schedules"
          canExport
          defaultSortFieldId="name"
          defaultSortAsc
        />
      )}
    </>
  );
};
