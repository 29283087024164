import { useTranslation } from 'react-i18next';
import NotificationDetail from './NotificationDetail';
import { getLanguage } from '@/src/utilities/storage';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';

const NotificationDetailContainer = (props: any) => {
  const { row: data } = props;
  const { t } = useTranslation('notifications');
  const lang = getLanguage()?.split('-')[0];

  const recipientColumns: CustomDataTableColumnsProps<any>[] = [
    {
      id: 'property',
      name: t('notifications_detail_table_property_code_column'),
      selector: (row: any) => row?.residency?.property?.code || '-',
      sortable: false,
    },
    {
      id: 'name',
      name: t('notifications_detail_table_name_column'),
      selector: (row: any) => row?.person?.full_name || '-',
      sortable: false,
    },
    {
      id: 'email',
      name: t('notifications_detail_table_email_column'),
      selector: (row: any) => row.person.email || '-',
      sortable: true,
      sortField: 'concept_name',
    },
    {
      id: 'phoneNumber',
      name: t('notifications_detail_table_phone_number_column'),
      selector: (row: any) => row.person.phone_number || '-',
      sortable: true,
      sortField: 'quantity',
      center: true,
      filters: [
        {
          id: 'quantity',
          query: 'Quantity',
          type: 'min-max',
        },
      ],
    },
  ];

  const childProps = {
    ...props,
    t,
    lang,
    getFormattedDateByLanguague,
    getFormattedDateFullYearEs,
    getFormattedDateFullYearEn,
    recipientColumns,
    data,
  };

  return <NotificationDetail {...childProps} />;
};

export default NotificationDetailContainer;
