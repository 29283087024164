import { useTranslation } from 'react-i18next';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getNotificationRecipient, sendNotifications } from '@/src/api/endpoints/notifications';
import { Recipients } from '../types';
import NotificationsPage from './NewNotificationPage';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';

const getInitialValues = () => ({
  guestsGroup: [],
  title: '',
  message: '',
  smsCheckBox: false,
  appCheckBox: false,
  emailCheckBox: false,
});

const getValidationSchema = ({ t }: any) =>
  Yup.lazy(() =>
    Yup.object().shape({
      guestsGroup: Yup.array()
        .min(1, t('required_field', { ns: 'errors' }))
        .required(),
      title: Yup.string().required(t('required_field', { ns: 'errors' })),
      message: Yup.string().required(t('required_field', { ns: 'errors' })),
      smsCheckBox: Yup.boolean(),
      appCheckBox: Yup.boolean(),
      emailCheckBox: Yup.boolean(),
    }),
  );

const NewNotificationPageContainer = () => {
  const { t } = useTranslation('notifications');
  const [searchText, setSearchText] = useState('');
  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );
  const [options, setOptions] = useState<any[]>([]);

  const [refreshForm, setRefreshForm] = useState(false);

  const { SnackBar, setSnackBarMessage } = useSnackBar();

  const { mutate, isLoading: recipeListIsLoading } = useMutation(getNotificationRecipient);

  const { mutate: sendNotification, isLoading: isLoadingSubmit } = useMutation(sendNotifications, {
    onError: (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
      setSnackBarMessage(t('error'), 'error');
    },
    onSuccess: () => {
      setSearchText('');
      setRefreshForm(true);
      setSnackBarMessage(t('notification_send_success'));
    },
  });
  const onSubmit = (data: FormikValues) => {
    const sentNotification = {
      title: data?.title,
      message: data?.message,
      modes: [
        ...(data?.smsCheckBox ? ['SMS'] : []),
        ...(data?.appCheckBox ? ['PUSH'] : []),
        ...(data?.emailCheckBox ? ['EMAIL'] : []),
      ],
      recipients: data?.guestsGroup.map((recipe: any) => ({
        person_id: recipe?.person?.id,
        residency_id: recipe?.residency?.id,
      })),
    };
    sendNotification(sentNotification);
  };

  const { handleSubmit, values, setFieldValue, errors, resetForm } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema({ t }),
  });

  const handleInputChange = (_: any, value: any) => {
    setSearchText(value);
    if (value === '') {
      setOptions([]);
      return;
    }
    mutate(value, {
      onError: (e: any) => {
        setErrorMessage(e.response.data.error_messages[0]);
        setSnackBarMessage(t('error'), 'error');
      },
      onSuccess: (res: any) => {
        setOptions(
          res?.data?.recipients?.filter(
            (option: Recipients) =>
              !values?.guestsGroup?.some((guest: Recipients) => guest?.person?.id === option?.person?.id),
          ),
        );
      },
    });
  };

  useEffect(() => {
    if (refreshForm)
      resetForm({
        values: {
          guestsGroup: [],
          title: '',
          message: '',
          smsCheckBox: false,
          appCheckBox: false,
          emailCheckBox: false,
        },
      });
    setRefreshForm(false);
  }, [refreshForm]);

  const childProps = {
    t,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    searchText,
    setSearchText,
    options,
    handleInputChange,
    recipeListIsLoading,
    SnackBar,
    errorMessage,
    isLoadingSubmit,
  };

  return <NotificationsPage {...childProps} />;
};

export default NewNotificationPageContainer;
