import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useColumns } from './useColumns';
import DataTableStyled from '@/src/components/custom-data-table/CustomDataTable.styled';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import { getReportDetailsByProperty } from '@/src/api/endpoints/packages';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import CustomLoader from '@/src/components/custom-loader';

export const ReportDetailsPage = (props: any) => {
  const { t } = useTranslation();
  const { columns } = useColumns();
  const { row } = props;

  const { data, isLoading } = useQuery(['valetDetail'], () => getReportDetailsByProperty(row.property.code), {
    select: (res: any) => res?.data?.report,
  });

  return (
    <Card>
      <CardHeader
        title={`${t('package_reports_detail_table_title', { ns: 'packages' })} ${row.property.code}`}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent sx={{ padding: '0px !important' }}>
        <DataTableStyled
          dense
          columns={columns}
          data={data?.type_reports}
          noDataComponent={<AlertNoData title={t('no_data_was_found')} />}
          theme="palierGridTheme"
          paginationTotalRows={data?.type_reports.length}
          paginationPerPage={20}
          pagination
          paginationComponentOptions={{ noRowsPerPage: true }}
          progressPending={isLoading}
          progressComponent={<CustomLoader />}
        />

        <Box component="div" sx={{ width: '100%', textAlign: 'right', pt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={4} lg={6} sx={{ padding: '10px 0 0' }}>
              <InfoLabel
                first={t('reports_detail_page_door_to_door_fee_amount', { ns: 'packages' })}
                second={`$${row?.door_to_door_fee_amount as string}`}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={6}>
              <InfoLabel
                first={t('reports_detail_page_storage_fee_amount', { ns: 'packages' })}
                second={`$${row?.storage_fee_amount as number}`}
              />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
