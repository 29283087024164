import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import CustomColumnRow from './abm/custom-column-row/CustomColumnRow';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import FormAvailabilityContainer from './abm/form-availability/FormAvailability.container';
import { ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { AvailabilitiesColumn } from './types';

export const useColumns = (agendaId: number) => {
  const { t } = useTranslation();

  const columns: CustomDataTableColumnsProps<AvailabilitiesColumn>[] = [
    {
      id: 'description',
      name: t('availabilities_page_name_column', { ns: 'bookings' }),
      selector: (row) => `${row.from} - ${row.to}`,
      cell: (row) => <CustomColumnRow row={row} />,
      sortField: 'description',
      sortable: true,
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'availabilities-create',
      component: () => (
        <CustomTableAction
          modalTitle="availabilities"
          component={({ close, row, setSnackBarMessage }: any) => (
            <FormAvailabilityContainer
              agendaId={agendaId}
              row={row}
              close={close}
              setSnackBarMessage={setSnackBarMessage}
            />
          )}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', sm: '53%' }}
        />
      ),
    },
  ];

  const actionColumns = [
    {
      id: 'edit',
      icon: (props: any) => <ModeEditOutlineRoundedIcon sx={props.sx} />,
      component: (props: any) => <FormAvailabilityContainer {...props} isEdit />,
      width: { xs: '90%', sm: '53%' },
    },
  ];

  return { columns, headerActions, actionColumns };
};
