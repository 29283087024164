import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { FormikErrors, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import CustomInput from '@/src/components/forms/CustomInput';
import { CustomDatePicker } from '@/src/components/date-picker/DatePicker';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import { CoverImageContainer } from '../BannerForm.styled';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { handlerDateSelector } from '@/src/utilities/helpers/utils';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

const BannerFormInformation = (props: Props) => {
  const { values, ImageUploader, wrongCoverImages, deleteImage, errors, setFieldValue } = props;

  const { t } = useTranslation('banners');

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography component="span" variant="subtitle2">
          {t('attached_banner_image')}
        </Typography>
      </Grid>
      {values?.banner_image ? (
        <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative' }}>
          <Tooltip title={t('remove_item_tooltip_msn', { ns: 'news' })}>
            <IconButton onClick={() => deleteImage('banner_image')} sx={{ position: 'absolute', right: -15, top: -14 }}>
              <CancelRoundedIcon />
            </IconButton>
          </Tooltip>
          <CustomCardMedia src={values?.banner_image} alt="cover-image" height="180px" />
        </Grid>
      ) : (
        <CoverImageContainer>
          <ImageUploader />
          <ErrorText error={wrongCoverImages?.code} />
          {errors?.publication_image_urls && <ErrorText error={t('attach_header_image', { ns: 'news' })} />}
        </CoverImageContainer>
      )}

      <Grid item xs={12} sm={12} md={12}>
        <CustomInput
          field="name"
          noError
          label={t('name')}
          placeholder={t('enter_name')}
          setFieldValue={setFieldValue}
          values={values}
          autoFocus
          maxLength={100}
          required
        />
        <ErrorText error={errors && errors?.name ? t('enter_name') : null} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <CustomDatePicker
          value={values?.start_date}
          minDate={new Date()}
          onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'start_date')}
          fieldValue="start_date"
          placeholder={t('start_date')}
          errorMessage={errors?.start_date as string}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <CustomDatePicker
          value={values?.end_date}
          minDate={new Date(values?.start_date)}
          onChange={(newValue: any): void => handlerDateSelector(newValue, setFieldValue, 'end_date')}
          fieldValue="end_date"
          placeholder={t('end_date')}
          errorMessage={errors?.end_date as string}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <textarea
          value={values?.description}
          onChange={(e) => setFieldValue('description', e.target.value)}
          style={{ height: '200px', width: '100%' }}
          maxLength={1000}
        />
        {errors?.description && <ErrorText error={t('enter_message')} />}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <CustomInput
          field="target_link_url"
          noError
          label={t('target_link_url')}
          placeholder={t('enter_target_link')}
          setFieldValue={setFieldValue}
          values={values}
          autoFocus
          required
        />
        <ErrorText error={errors && errors?.target_link_url ? t('enter_target_link') : null} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <CustomInput
          field="section_order"
          noError
          label={t('section_order')}
          placeholder={t('enter_section_order')}
          setFieldValue={setFieldValue}
          values={values}
          inputType="number"
          autoFocus
          required
        />
        <ErrorText error={errors && errors?.section_order ? t('enter_section_order') : null} />
      </Grid>
    </Grid>
  );
};

const propTypes = {};

interface ExtraProps {
  values: FormikValues;
  ImageUploader: ({ disabled }: { disabled?: boolean }) => JSX.Element;
  wrongCoverImages: { code: any; error_messages: any } | null | undefined;
  deleteImage: (value: any, url?: string) => void;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
BannerFormInformation.propTypes = propTypes;

export default BannerFormInformation;
