import { useTranslation } from 'react-i18next';
// import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { VisibilityRounded } from '@mui/icons-material';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import { PaymentRow, paymentsStates } from './types';
import PaymentDetail from './paymentDetail';
import TextLabel from '@/src/components/text-label/TextLabel';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import usePaymentStatesFilters from '@/src/hooks/filters/paymentStates.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { apartments } = useApartmentsFilters();
  const { paymentStates } = usePaymentStatesFilters();

  const columns: CustomDataTableColumnsProps<PaymentRow>[] = [
    {
      id: 'date',
      name: t('date', { ns: 'charges' }),
      selector: (row: PaymentRow) =>
        row?.residency
          ? `${getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row?.residency.from,
            )} - ${getFormattedDateByLanguague(
              lang,
              getFormattedDateFullYearEs,
              getFormattedDateFullYearEn,
              row?.residency.to,
            )}`
          : '-',
      sortField: 'creation_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'apartment ',
      name: t('apartment ', { ns: 'charges' }),
      selector: (row: PaymentRow) => row.residency?.property.code,
      sortField: 'property_code',
      sortable: true,
      center: true,
      filters: apartments,
    },
    {
      id: 'total_amount',
      name: t('amount', { ns: 'charges' }),
      selector: (row: PaymentRow) => row.amount,
      sortField: 'total_amount',
      center: true,
      sortable: true,
      filters: [
        {
          id: 'amount',
          query: 'Amount',
          type: 'min-max',
        },
      ],
    },
    {
      id: 'status',
      name: t('status', { ns: 'charges' }),
      sortField: 'payment_state',
      selector: (row) => t(`${row?.payment_state?.name.toLocaleUpperCase()}`, { ns: 'charges' }),
      cell: (row: PaymentRow) => (
        <TextLabel
          text={t(`${row?.payment_state?.name.toLocaleUpperCase()}`, { ns: 'charges' })}
          color="#F7F7F7"
          padding="2px 12px"
          width="100px"
          bg={paymentsStates[row?.payment_state?.code]}
        />
      ),
      center: true,
      sortable: true,
      filters: paymentStates,
    },
  ];

  const headerActions: ActionHeader[] = [];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      customizedTitle: true,
      icon: (props: any) => <VisibilityRounded sx={props.sx} />,
      component: (props: any) => <PaymentDetail {...props} PaymentId={props.row.id} />,
      width: { xs: '100%', sm: '500px', lg: '800px' },
    },
  ];

  return { columns, headerActions, actionColumns };
};
