import { Autocomplete, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { PropertiesProps, PropertyType } from '../../../types';
import CustomCurrencyInput from '@/src/components/forms/CustomCurrencyInput';
import { getProperties } from '@/src/api/endpoints/residents';
import CustomDateTimePicker from '@/src/components/date-time-picker';
import { useEffect, useState } from 'react';

const currentTime = new Date();
const currentYear = currentTime.getFullYear();
const currentMonth = currentTime.getMonth();
const currentDay = currentTime.getDate();

const lastYear = `${currentYear - 1}/${currentMonth}/${currentDay}`;
const minValue = new Date(lastYear).getTime();

const getTomorrow = (value: number) => {
  if (value) {
    const tmrwDt = new Date(value);
    const tmrw = tmrwDt.setDate(tmrwDt.getDate() + 1);
    return tmrw;
  }
  return value;
};

export const Properties = ({
  setFieldValue,
  errors,
  functional_unit,
  from,
  to,
  datePickerDisabled,
  deposit_amount,
  has_deposit,
  lease_signer,
  isEdit,
}: PropertiesProps) => {
  const { t } = useTranslation();
  const handleCheck = () => {
    setFieldValue('deposit_amount', '');
    setFieldValue('has_deposit', !has_deposit);
  };
  
  const [propertiesList, setPropertiesList] = useState([]);

  const { data, isLoading } = useQuery(
    ['getProperties', from, to],
    async () => getProperties({ fromDate: from, toDate: to }), 
    {
      enabled: !!from && !!to,
      onSuccess: (response) => {
        const properties = response?.data?.properties?.map((prop: PropertyType) => prop.code);
        if(!isEdit) 
          setFieldValue('functional_unit', response?.data?.properties[0]?.code )
        setPropertiesList(properties ?? []);
      },
    }
  );

  return (
    <>
      <Grid xs={12} md={6} lg={4} item>
        <CustomDateTimePicker
          value={from}
          minDate={minValue}
          onChange={(newValue: any): void => {
            setFieldValue('from', new Date(newValue.$d).getTime());
          }}
          fieldValue="from"
          disabled={datePickerDisabled || !lease_signer}
          errorMessage={errors?.from as string}
          required
        />
      </Grid>
      <Grid xs={12} md={6} lg={4} item>
        <CustomDateTimePicker
          value={to}
          onChange={(newValue: any): void => {
            setFieldValue('to', new Date(newValue.$d).getTime());
          }}
          minDate={getTomorrow(from)}
          fieldValue="to"
          disabled={datePickerDisabled || !lease_signer}
          errorMessage={errors?.to as string}
          required
        />
      </Grid>
      
      {from && to && !isLoading && (
        <Grid xs={12} md={12} lg={4} item>
          <Autocomplete
            id="functional_unit"
            size="small"
            disabled={isEdit}
            value={functional_unit || null}
            getOptionLabel={(option) => option}
            options={propertiesList ?? []}
            onChange={(e, value) => {
              setFieldValue('functional_unit', value ?? '');
            }}
            sx={{ backgroundColor: isEdit ? 'rgba(0,0,0,0.05)' : '' }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={`${t('room', { ns: 'residents' })}`}
                placeholder={t('room_form_field_placeholder', { ns: 'residents' })}
                error={errors.functional_unit !== undefined}
                helperText={errors.functional_unit?.toString()}
                sx={{
                  input: {
                    WebkitTextFillColor: isEdit ? 'rgba(0,0,0,0.6) !important' : '',
                  },
                  label: {
                    color: isEdit ? 'rgba(0,0,0,1) !important' : '',
                  },
                }}
              />
            )}
          />
        </Grid>
      )}
      <Grid xs={12} container spacing={2} item>
        <Grid xs={12} md={6} lg={4} item>
          <FormControlLabel
            disabled
            control={<Checkbox checked={lease_signer} />}
            label={t('lease_signer', { ns: 'residents' })}
          />
        </Grid>
        <Grid xs={12} md={6} lg={4} item>
          <FormControlLabel
            disabled={!lease_signer}
            onChange={handleCheck}
            control={<Checkbox checked={has_deposit} />}
            label={t('has_amount', { ns: 'residents' })}
          />
        </Grid>
        <Grid xs={12} md={6} lg={4} item>
          <CustomCurrencyInput
            field="deposit_amount"
            value={deposit_amount}
            label={t('deposit_amount', { ns: 'residents' })}
            setFieldValue={setFieldValue}
            disabled={!has_deposit || !lease_signer}
            error={errors?.deposit_amount}
          />
        </Grid>
      </Grid>
    </>
  );
};