import { Avatar, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
  getFormattedLocalFullDateNumberEn,
  getFormattedLocalFullDateNumberEs,
} from '@/src/utilities/helpers/dateParser';
import { guestsStates, GuestInfoProps } from '../types';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import { FadeIn } from '@/src/components/animations';
import { confirmGuest, invitationDetail } from '@/src/api/endpoints/guests';
import { CustomText, SectionTitle } from './CustomText';
import { getLanguage } from '@/src/utilities/storage';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import { BoxButton, BoxContainer2, BoxContainerDate, BoxContainerModal, BoxTitle } from './GuestInfo.styled';
import CustomDataTableForGuests from './CustomDataTableForGuests';
import { useColumns } from './useColumns';
import { GuestDetail } from './types';

const GuestInfo = ({ close, row, setSnackBarMessage }: GuestInfoProps) => {
  const { t } = useTranslation();
  const { columns } = useColumns();

  const {
    data: invitationDetailData,
    isLoading,
    remove,
  } = useQuery(['invitationDetail', row.id], () => invitationDetail(row.id), {
    select: (data) => data?.data.guest ?? ({} as GuestDetail),
  });
  const joined = ['Admitido', 'Admitted'].includes(invitationDetailData?.invitation_status);
  const { mutate, isLoading: isInvitationConfirmed } = useMutation(confirmGuest);
  const handleConfirm = () => {
    mutate(row.id, {
      onSuccess: () => {
        close();
        setSnackBarMessage(t('success'));
      },
      onError: ({ response }: any) => {
        close();
        setSnackBarMessage(t(response.data.error_messages[0].code, { ns: 'errors' }), 'error');
      },
      onSettled: () => {
        remove();
      },
    });
  };
  const lang = getLanguage()?.split('-')[0];
  return (
    <Box component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
      <BoxTitle>
        <TitleWithStatus
          title={t('modal_detail_title', { ns: 'guests' })}
          statusText={row.status}
          statusBg={guestsStates[row.status]}
          onClose={close}
          addMargin={false}
        />
        {isLoading ? (
          <CircularSpinner />
        ) : (
          <>
            <BoxContainer2>
              <BoxContainerModal>
                <FadeIn duration="2s">
                  <Avatar
                    alt={invitationDetailData?.name}
                    src={invitationDetailData?.selfie_url ?? ''}
                    sx={{ width: 120, height: 120, margin: 'auto', mt: { xs: 1, md: 6 } }}
                  />
                </FadeIn>
                <SectionTitle
                  title="modal_detail_sub_title_4"
                  mt={{ xs: 2.2, md: !invitationDetailData.invitation.is_recurring ? 9.5 : 5.4 }}
                />
                <CustomText title="guests_page_invitation_id_column" value={invitationDetailData.invitation.id} />
                <CustomText
                  title="guests_page_type"
                  value={t(
                    invitationDetailData?.invitation.is_recurring
                      ? 'guests_page_recurrent_column'
                      : 'guests_page_one_time_column',
                    { ns: 'guests' },
                  )}
                />
                <CustomText
                  title="modal_detail_input_pre_authorization_date"
                  value={getFormattedDateByLanguague(
                    lang,
                    getFormattedLocalFullDateNumberEs,
                    getFormattedLocalFullDateNumberEn,
                    invitationDetailData.invitation.authorization_date,
                  )}
                />
                <CustomText
                  title="valid_for"
                  value={
                    invitationDetailData.invitation.date_to === null
                      ? t('guests_page_never_expires', { ns: 'guests' })
                      : `${getFormattedDateByLanguague(
                          lang,
                          getFormattedDateFullYearEs,
                          getFormattedDateFullYearEn,
                          invitationDetailData?.invitation.date_from,
                        )} ${
                          invitationDetailData.invitation.date_from !== invitationDetailData.invitation.date_to
                            ? `- ${getFormattedDateByLanguague(
                                lang,
                                getFormattedDateFullYearEs,
                                getFormattedDateFullYearEn,
                                invitationDetailData?.invitation.date_to,
                              )}`
                            : ''
                        }
                     `
                  }
                />
              </BoxContainerModal>
              <BoxContainerDate>
                <SectionTitle title="modal_detail_sub_title_2" mt={1} />
                <CustomText
                  title="name"
                  resource="common"
                  value={`${invitationDetailData.name} ${invitationDetailData.surname}`}
                />
                <CustomText title="modal_detail_input_phone" value={invitationDetailData.phone_number} />
                <CustomText title="modal_detail_input_email" value={invitationDetailData.email} />
                <CustomText title="modal_detail_input_type" value={invitationDetailData.invitation.type.name} />
                {!invitationDetailData.invitation.is_recurring && joined && (
                  <CustomText
                    title="modal_detail_input_date_ingress"
                    value={
                      invitationDetailData?.check_ins?.[0]?.check_in_date
                        ? getFormattedDateByLanguague(
                            lang,
                            getFormattedLocalFullDateNumberEs,
                            getFormattedLocalFullDateNumberEn,
                            invitationDetailData?.check_ins[0].check_in_date,
                          )
                        : '-'
                    }
                  />
                )}
                <CustomText title="modal_detail_input_number_companions" value={invitationDetailData.guests_number} />
                <SectionTitle title="modal_detail_sub_title_1" mt={2} />
                <CustomText
                  title="name"
                  resource="common"
                  value={`${invitationDetailData.resident.name} ${invitationDetailData.resident.surname}`}
                />
                <CustomText title="modal_detail_input_phone" value={invitationDetailData.resident.phone_number} />
                <CustomText title="modal_detail_input_functional_unit" value={invitationDetailData.resident.property} />
              </BoxContainerDate>
            </BoxContainer2>
            {invitationDetailData.invitation.is_recurring && (
              <>
                {' '}
                <SectionTitle title="modal_detail_sub_title_5" mt={{ xs: 1, md: 2 }} />
                <CustomDataTableForGuests columns={columns} data={invitationDetailData?.check_ins ?? []} />
              </>
            )}
            {(!joined || invitationDetailData.invitation.is_recurring) && (
              <BoxButton>
                <LoadingButton
                  loading={isInvitationConfirmed}
                  type="button"
                  onClick={handleConfirm}
                  variant="contained"
                  size="small"
                  sx={{
                    width: { xs: '100%', md: '50%' },
                  }}
                >
                  {t('modal_detail_confirm_entry_button_text', { ns: 'guests' })}
                </LoadingButton>
              </BoxButton>
            )}
          </>
        )}
      </BoxTitle>
    </Box>
  );
};

export default GuestInfo;
