import React, { useEffect } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { approveFamily, getFamilyByResidencyId, rejectFamily } from '@/src/api/endpoints/houseHold';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import ApproveAndReject from '@/src/components/approve-reject/ApproveAndReject';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import { RelativeModalProps } from './types';
import CustomTextArea from '@/src/components/forms/text-area';
import { physicalAddress } from '@/src/utilities/helpers/stringsHelper';
import { getLanguage } from '@/src/utilities/storage';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import PdfShowFile from '@/src/components/pdf-show-file/PdfShowFile';
import ImageShow from '@/src/components/image-show/ImageShow';
import { commonStatus } from '../common/types';

const ResidentRequestDetail = (props: RelativeModalProps) => {
  const { close, row, isOwner } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    [`get_relative_or_owner_by_id_${row.id}`],
    () => getFamilyByResidencyId(row?.relative?.id, row?.residency?.id),
    {
      select: (d) => d?.data?.relative_residency,
    },
  );

  const Approved = ['Approved', 'Aprobado'];
  const lang = getLanguage()?.split('-')[0];
  const [state, setState] = React.useState(false);

  useEffect(
    () =>
      function cleanUp() {
        queryClient.resetQueries([`get_family_by_id_${row.id}`]);
      },
    [],
  );

  return (
    <Box component="div" sx={{ marginY: '5px' }}>
      <Grid container spacing={2}>
        <TitleWithStatus
          title={t('relatives_detail_view_request', { ns: 'cdtModal' })}
          statusText={data?.relative?.state?.name ?? ''}
          statusBg={
            data?.relative && !isOwner
              ? commonStatus[data?.relative?.state?.code.replaceAll(' ', '_').replaceAll('ó', 'o')]
              : ''
          }
          onClose={close}
        />
        <Grid item xs={12}>
          <Divider sx={{ mt: '-15px' }} />
        </Grid>
        {isLoading || !data?.relative ? (
          <CircularSpinner />
        ) : (
          <>
            {data?.residency?.property?.code && (
              <Grid item xs={12} sm={6}>
                <InfoLabel
                  first={t('user_detail_apartment', { ns: 'customerUsers' })}
                  second={data?.residency?.property?.code}
                />
              </Grid>
            )}
            {data?.relative?.relationship_type && (
              <Grid item xs={12} sm={6}>
                <InfoLabel
                  first={t('relationship', { ns: 'customerUsers' })}
                  second={data?.relative.relationship_type.name}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('name')}
                second={`${data?.relative?.person.name} ${data?.relative?.person.surname}`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('alias', { ns: 'customerUsers' })} second={data?.relative.person.alias || ' -'} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel first={t('email')} second={data?.relative?.person.email} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('residents_list_phone_number_column', { ns: 'customerUsers' })}
                second={data?.relative?.person.full_phone_number ?? ' -'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('birthday', { ns: 'customerUsers' })}
                second={
                  data?.relative?.person.birthday
                    ? getFormattedDateByLanguague(
                        lang,
                        getFormattedDateFullYearEs,
                        getFormattedDateFullYearEn,
                        data?.relative.person.birthday,
                      )
                    : '-'
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('physical_address', { ns: 'customerUsers' })}
                second={
                  physicalAddress(
                    data?.relative?.person.street_name,
                    data?.relative?.person.street_number,
                    data?.relative?.person.apartment_number,
                    data?.relative?.person.floor_number,
                  ) || '-'
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('zip_code', { ns: 'customerUsers' })}
                second={data?.relative?.person.zip_code ?? ' -'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('country', { ns: 'customerUsers' })}
                second={data?.relative?.person.country?.name ?? ' -'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('state', { ns: 'customerUsers' })}
                second={data?.relative?.person.state?.name ?? ' -'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('city', { ns: 'customerUsers' })}
                second={data?.relative?.person.city?.name ?? ' -'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('request_background_check_copy', { ns: 'customerUsers' })}
                second={data?.relative?.person.request_background_check_copy ? t('yes') : 'No'}
              />
            </Grid>
            {data?.relative?.rejection_reason && (
              <Grid item xs={12}>
                <InfoLabel first={t('rejection_reason_title', { ns: 'customerUsers' })} second="" />
                <CustomTextArea
                  placeholder={t('reason_rejection_placeholder', { ns: 'cdtModal' })}
                  minRows={3}
                  style={{ width: '100%', height: '40px', outline: 'none' }}
                  value={data?.relative.rejection_reason}
                  maxLength={500}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <InfoLabel
                first={t('identification_number', { ns: 'customerUsers' })}
                second={data?.relative?.person.identification_number ?? '-'}
              />
            </Grid>
            {data?.relative?.person?.identification_file_url && (
              <Grid item xs={12} sm={7}>
                <InfoLabel first={t('documents', { ns: 'customerUsers' })} second="" />
                {data?.relative?.person?.identification_file_url?.slice(
                  -3,
                  data?.relative.person?.identification_file_url.length,
                ) === 'pdf' ? (
                  <PdfShowFile file={data?.relative.person?.identification_file_url} />
                ) : (
                  <ImageShow
                    url={data?.relative?.person?.identification_file_url}
                    open={state}
                    handleOpen={() => setState(true)}
                    handleClose={() => setState(false)}
                  />
                )}
              </Grid>
            )}
            {!Approved.includes(data?.relative?.state?.name) && !isOwner && (
              <Grid item xs={12}>
                <ApproveAndReject
                  {...props}
                  resource="relative"
                  fnApprove={approveFamily}
                  fnReject={rejectFamily}
                  reasonRejection={data?.relative?.state.code !== 'InReview'}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ResidentRequestDetail;
