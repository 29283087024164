import React from 'react';
import FilterColumnIcon from './FilterColumnIcon';
import { FilterByColumn } from '../../types/CustomDataTableColumnProps';
import { useCustomDataTable } from '../../CustomDataTableContext';

const FilterColumnIconContainer = (props: FilterColumnIconContainerProps) => {
  const { orderDescending, sortField, orderField, handleSort, filters } = props;
  const { filterString, setFilterString } = useCustomDataTable();

  const getInitialOrder = () => {
    if (orderField !== sortField) return null;
    return orderDescending ? 'desc' : 'asc';
  };

  const [order, setOrder] = React.useState<'asc' | 'desc' | null>(getInitialOrder());
  const [localFilterString, setLocalFilterString] = React.useState<string>(filterString);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmitFilters = () => {
    if (order !== null) handleSort({ sortField }, String(order));
    if (filters) {
      setFilterString(localFilterString);
    }
    handleClose();
  };
  const handleClearFilters = () => {
    setOrder(getInitialOrder());
    setLocalFilterString((prev) => {
      const variables = prev.split('&');
      variables.shift();
      let str = '';

      const queries = [...new Set(filters?.map((f) => f.query))];

      variables.forEach((v) => {
        let aux = '';
        queries?.forEach((q) => {
          if (v.includes(q)) {
            aux += v;
          }
        });
        if (!aux) str += `&${v}`;
      });
      setFilterString(str);
      return str;
    });
    handleClose();
  };

  const isDotActive: boolean = React.useMemo(() => {
    if (filters) {
      const res = filters?.filter((f) => filterString.includes(f.query));
      return res.length > 0;
    }
    return false;
  }, [filterString]);

  const childProps = {
    ...props,
    // filters: getGroupedFilters(),
    isDotActive,
    filters,
    anchorEl,
    open,
    sortField,
    handleClick,
    handleClose,
    order,
    setOrder,
    handleSubmitFilters,
    handleClearFilters,
    localFilterString,
    setLocalFilterString,
  };
  return <FilterColumnIcon {...childProps} />;
};

interface FilterColumnIconContainerProps {
  name: string;
  filters?: Array<FilterByColumn>;
  sortable?: boolean;
  orderDescending: boolean;
  sortField: string;
  orderField?: string;
  handleSort: (column: any, sortDirection: string) => void;
  center?: boolean;
  right?: boolean;
}

export default FilterColumnIconContainer;
