import { useTranslation } from 'react-i18next';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { NotificationType } from '../types';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { getLanguage } from '@/src/utilities/storage';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
  getFormattedDateFullYearEn,
} from '@/src/utilities/helpers/dateParser';
import { ActionColumn } from '@/src/components/custom-data-table/types/DataTableFormModal';
import NotificationDetail from './notification-detail';

export const useColumns = () => {
  const { t } = useTranslation('notifications');
  const lang = getLanguage()?.split('-')[0];

  const columns: CustomDataTableColumnsProps<NotificationType>[] = [
    {
      id: 'date',
      name: t('notifications_list_date_column'),
      selector: (row: NotificationType) =>
        row.date
          ? getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row?.date)
          : '-',
      sortField: 'date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'date',
          query: 'Date',
          type: 'date',
        },
      ],
    },
    {
      id: 'title',
      name: t('notifications_list_title_column'),
      selector: (row: NotificationType) => row?.title,
      sortField: 'title',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'title',
          query: 'Title',
          type: 'contains',
        },
      ],
    },
    {
      id: 'recipient',
      name: t('notifications_list_recipient_column'),
      selector: (row: NotificationType) => row?.recipient?.person?.full_name ?? '-',
      sortField: 'recipient',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'recipient',
          query: 'Recipient',
          type: 'contains',
        },
      ],
    },
    {
      id: 'notificationMode',
      name: t('notifications_list_notification_mode_column'),
      selector: (row: NotificationType) => row?.modes?.join(', ') ?? '-',
      sortField: 'notificationMode',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'notificationMode',
          query: 'NotificationMode',
          type: 'contains',
        },
      ],
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <VisibilityRoundedIcon sx={props.sx} />,
      component: (props: any) => <NotificationDetail {...props} />,
      width: { xs: '90%', sm: '80%', lg: '60%' },
    },
  ];

  return { t, columns, actionColumns };
};
