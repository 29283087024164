import { FormikErrors, FormikValues } from 'formik';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import BannerFormInformation from './BannerFormInformation';
import useImageUploader from '@/src/components/image-uploader/useImageUploader';

const BannerFormInformationContainer = (props: Props) => {
  const { values, errors, setFieldValue, setDisableButton } = props;

  const deleteImage = (value: any, url?: string) => {
    setFieldValue(
      value,
      Array.isArray(values[`${value}`]) ? values?.[`${value}`].filter((element: any) => element.image_url !== url) : '',
    );
  };

  const { ImageUploader, wrongImages: wrongCoverImages } = useImageUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'banner_image',
    setFieldValue,
    setDisableForm: setDisableButton,
  });

  const childProps = {
    values,
    ImageUploader,
    wrongCoverImages,
    deleteImage,
    errors,
    setFieldValue,
  };

  return <BannerFormInformation {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  setFieldError: (field: string, value: string | undefined) => void;
  errorMessage: string | null;
  isLoadingSubmit: boolean;
  setDisableButton: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
BannerFormInformationContainer.propTypes = propTypes;

export default BannerFormInformationContainer;
