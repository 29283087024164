import { AlertColor } from '@mui/material';

export type TGuestsStateOptions = {
  [key: string]: string;
};

export const guestsStates: TGuestsStateOptions = {
  Admitido: '#424867',
  Admitted: '#424867',
  Confirmado: '#2e7d32',
  Confirmed: '#2e7d32',
};

export interface Guest {
  id: number;
  guest: {
    name: string;
    surname: string;
  };
  resident: {
    name: string;
    surname: string;
  };
  guests_number: number;
  invitation: {
    is_recurring: boolean;
    date_from: number;
    date_to: number;
    hour_from: string;
    hour_to: string;
    type: {
      id: number;
      code: string;
      name: string;
    };
  };
  status: string;
  property: string;
}
export interface GuestInfoProps {
  close: () => void;
  setSnackBarMessage: (message: string, sever?: AlertColor) => void;
  row: Guest;
}

export interface GuestConfirmationProps {
  close: () => void;
  setSnackBarMessage: (message: string, sever?: AlertColor) => void;
  row: Guest;
}
