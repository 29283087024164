import { Autocomplete, Box, Checkbox, Chip, Grid, TextField, Typography } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import PropTypes from 'prop-types';
import { Dispatch, FormEvent, SetStateAction } from 'react';
import { LoadingButton } from '@mui/lab';
import { Recipients } from '../types';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import CustomInput from '@/src/components/forms/CustomInput';
import { ErrorHelper } from '@/src/components/error/error-helper/ErrorHelper';

export const NewNotificationPage = (NotificationsProps: NotificationsProps) => {
  const {
    t,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    options,
    handleInputChange,
    recipeListIsLoading,
    SnackBar,
    errorMessage,
    isLoadingSubmit,
  } = NotificationsProps;

  return (
    <>
      <Box component="form" onSubmit={handleSubmit}>
        <Typography variant="h5" component="h1">
          {t('notifications')}
        </Typography>
        <Grid container spacing={2} pt={1} direction="column">
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="guestsGroup"
              size="small"
              value={(values?.guestsGroup as any[]) ?? []}
              loading={recipeListIsLoading}
              getOptionLabel={(option) =>
                `${option?.residency?.property?.code} - ${option?.person?.full_name} - (${option?.person?.full_phone_number}) - ${option?.person?.email}`
              }
              options={
                options?.filter(
                  (option) =>
                    !values?.guestsGroup?.some((guest: Recipients) => guest?.person?.id === option?.person?.id),
                ) ?? []
              }
              onChange={(e, value: any) => {
                setFieldValue('guestsGroup', value ?? '');
              }}
              onInputChange={(e, value: any) => {
                handleInputChange(e, value);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    label={`${option?.residency?.property?.code} - ${option?.person?.full_name}`}
                    {...getTagProps({ index })}
                    key={option.id}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`${t('guests_form_guests_groups_field_label')} * `}
                  placeholder={t('guests_form_guests_groups_field_placeholder')}
                  error={!!errors.guestsGroup}
                  helperText={errors.guestsGroup as string}
                />
              )}
              renderOption={(props, option: any) => (
                <li {...props} key={option?.person?.id}>
                  <span style={{ fontWeight: 'bold' }}>
                    {option?.residency?.property?.code} - {option?.person?.full_name}
                  </span>
                  &nbsp;({option?.person?.full_phone_number}) - {option?.person?.email}
                </li>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              field="title"
              errors={errors}
              label={t('guests_form_title_field_label')}
              placeholder={t('guests_form_title_field_placeholder')}
              setFieldValue={setFieldValue}
              values={values}
              autoFocus
              maxLength={50}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder={t('guests_form_message_field_placeholder')}
              type="text"
              label={t('guests_form_message_field_label')}
              onChange={(e) => setFieldValue('message', e.target.value)}
              size="small"
              sx={{ width: '100%' }}
              fullWidth
              multiline
              rows={10}
              value={values?.message}
              required
              inputProps={{
                maxLength: 1500,
              }}
            />
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <Typography sx={{ flexShrink: 0 }}>
                <Checkbox
                  checked={values?.smsCheckBox ?? false}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  onChange={(e) => setFieldValue('smsCheckBox', e.target.checked)}
                />
                {t('guests_form_checkbox_sms_label')}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={{ flexShrink: 0 }}>
                <Checkbox
                  checked={values?.appCheckBox ?? false}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  onChange={(e) => setFieldValue('appCheckBox', e.target.checked)}
                />
                {t('guests_form_checkbox_app_label')}
              </Typography>
            </Grid>

            <Grid item>
              <Typography sx={{ flexShrink: 0 }}>
                <Checkbox
                  checked={values?.emailCheckBox ?? false}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                  onChange={(e) => setFieldValue('emailCheckBox', e.target.checked)}
                />
                {t('guests_form_checkbox_email_label')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid item xs={12} sm={8} md={6}>
            <ErrorHelper error={errorMessage} />
          </Grid>
        )}
        <Grid container justifyContent="flex-end" mt="20px">
          <LoadingButton
            loading={isLoadingSubmit}
            variant="contained"
            size="small"
            type="submit"
            sx={{
              width: { xs: '100%', sm: 'auto' },
              height: '40px',
              minWidth: '150px',
            }}
          >
            {t('form_button_submit_text')}
          </LoadingButton>
        </Grid>
      </Box>
      <SnackBar />
    </>
  );
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

interface ExtraProps {
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  values: FormikValues;
  setFieldValue: (field: string, value: any) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  errors: FormikErrors<FormikValues>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  handleInputChange: (_: any, value: any) => void;
  options: Recipients[] | undefined;
  recipeListIsLoading: boolean;
  SnackBar: () => JSX.Element;
  errorMessage: { code: string; message: string; property_name: string } | null;
  isLoadingSubmit: boolean;
}

interface NotificationsProps extends InferPropsExtended<typeof propTypes, ExtraProps> {}
NewNotificationPage.propTypes = propTypes;

export default NewNotificationPage;
