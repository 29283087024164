import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import BannerForm from './BannerForm';
import { validate } from './BannerForm.validate';
import { getBanners, createBanner, editBanner } from '@/src/api/endpoints/banners';

const actualDate = new Date().getTime();

const BannerFormContainer = ({ bannerData, close, setSnackBarMessage, setRefreshGrid }: any) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const isEdit = typeof bannerData !== 'string';

  const getArticlesData = async () => {
    const response = await getBanners();
    return response?.data;
  };

  const { refetch } = useQuery(['publications'], getArticlesData);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('banners');
  const { mutate } = useMutation(createBanner);
  const { mutate: mutateEdit } = useMutation(editBanner);

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const getInitialValues = () => ({
    name: bannerData?.name ?? '',
    description: bannerData?.description ?? '',
    start_date: bannerData?.start_date ?? actualDate,
    end_date: bannerData?.end_date ?? actualDate,
    banner_image: bannerData?.image_url ?? '',
    target_link_url: bannerData?.target_link ?? '',
    section: 'Home',
    section_order: bannerData?.section_order ?? '',
    isEdit,
  });

  const onSubmit = (data: any) => {
    const body: any = {
      name: data?.name,
      description: data?.description.replace(/<[^>]*>/g, ''),
      start_date: data?.start_date,
      end_date: data?.end_date,
      image_url: data?.banner_image,
      target_link_url: data?.target_link_url,
      section: data?.section,
      section_order: data?.section_order,
    };

    if (!data.isEdit) {
      mutate(body, {
        onSuccess: async () => {
          refetch();
          setSnackBarMessage(t('successful_publication_creation_snackbar_text'));
          close();
          setRefreshGrid(true);
        },
        onError: async () => {
          setErrorMessage(t('failed_publication_creation_snackbar_text'));
        },
        onSettled: async () => {
          setIsLoading(false);
        },
      });
    } else {
      mutateEdit(
        { id: bannerData.id, body },
        {
          onSuccess: async () => {
            setIsLoading(false);
            refetch();
            setSnackBarMessage(t('successful_publication_edition_snackbar_text'));
            close();
            setRefreshGrid(true);
          },
          onError: async () => {
            setIsLoading(false);
            setErrorMessage(t('failed_publication_edition_snackbar_text'));
          },
        },
      );
    }
  };

  const { handleSubmit, values, setFieldValue, errors, setFieldError } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate(t),
  });

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    setFieldError,
    errors,
    isLoadingSubmit: isLoading,
    errorMessage,
    disableButton,
    setDisableButton,
    t,
    a11yProps,
    isEdit,
  };

  return <BannerForm {...childProps} />;
};

export default BannerFormContainer;
