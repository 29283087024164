import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { t } from 'i18next';
import { StyledTableCell, StyledTableRow } from './TableComponent.styled';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

const TableComponent = (props: Props) => {
  const { tableRows, removeItemFromRows } = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{`${t('news_form_table_name', { ns: 'news' })}`}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows?.map((row: any) => (
            <StyledTableRow key={row?.id}>
              <StyledTableCell component="th" scope="row">
                {row?.description ? row?.description : `${row?.name} ${row?.surname}`}
              </StyledTableCell>
              <StyledTableCell align="right">
                <IconButton aria-label="delete" onClick={() => removeItemFromRows(row)}>
                  <DeleteIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const propTypes = {};

interface ExtraProps {
  tableRows: any;
  removeItemFromRows: (item: any) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
TableComponent.propTypes = propTypes;

export default TableComponent;
