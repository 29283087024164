import { Box, styled } from '@mui/material';

export default {
  Content: styled(Box)(() => ({
    width: '100%',
  })),
  BoxLoader: styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  })),
};
