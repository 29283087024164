import { Autocomplete, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import TableComponent from '../table-component';
import CustomTabPanel from '../custom-tab-panel';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { Users } from './types';
import { TLanguagesOptions } from '@/src/utilities/helpers/commonTypes';
import { LoaderContainer } from '../loader-container/LoaderContainer';
import CustomLoader from '../custom-loader';

const AddresseeTab = (props: Props) => {
  const {
    t,
    tabValue,
    values,
    setFieldValue,
    deselectItem,
    groupList,
    errors,
    addToTable,
    tableRows,
    removeItemFromRows,
    usersData,
    isLoading,
  } = props;

  const filteredGroupList = groupList?.filter(
    (group: any) => !values?.userSelectedList?.some((selected: any) => selected?.id === group?.id),
  );

  const filteredUsersList = usersData?.filter(
    (user: any) => !values?.userSelectedList?.some((selected: any) => selected?.id === user?.id),
  );

  return (
    <CustomTabPanel value={tabValue} index={1}>
      {isLoading ? (
        <LoaderContainer>
          <CustomLoader />
        </LoaderContainer>
      ) : (
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={values?.userListTargetType}
              name="radio-buttons-group"
            >
              <Grid item xs={12} sm={12} md={12}>
                <FormControlLabel
                  value="group"
                  control={<Radio />}
                  onChange={() => {
                    deselectItem('group');
                  }}
                  label={`${t('news_form_for_groups_field_placeholder', { ns: 'news' })}`}
                />
                <FormControlLabel
                  value="user"
                  control={<Radio />}
                  onChange={() => {
                    deselectItem('user');
                  }}
                  label={`${t('news_form_for_user_field_placeholder', { ns: 'news' })}`}
                />
              </Grid>
            </RadioGroup>
          </FormControl>

          <Grid container spacing={1} sx={{ mt: '5px' }}>
            <Grid item xs={12} sm={10} md={10}>
              <Autocomplete
                id="selectedUser"
                size="small"
                value={values?.selectedUser || null}
                getOptionLabel={(option) =>
                  values?.userListTargetType === 'group' ? option.description : `${option.name} ${option.surname}`
                }
                options={values?.userListTargetType === 'group' ? filteredGroupList : filteredUsersList}
                filterSelectedOptions
                onChange={(e, value) => {
                  setFieldValue('selectedUser', value ?? '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={`${t('news_form_user_groups_field_label', { ns: 'news' })} * `}
                    placeholder={t('news_form_user_groups_field_placeholder', { ns: 'news' })}
                    error={errors.adminGroups !== undefined}
                    helperText={errors.adminGroups?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <Button
                variant="outlined"
                color="success"
                size="small"
                type="button"
                onClick={addToTable}
                sx={{ height: { xs: '100%', sm: '100%' }, width: '100%', fontSize: '10px' }}
              >
                {t('news_form_select', { ns: 'news' })}
              </Button>
            </Grid>
          </Grid>

          {tableRows?.length > 0 && (
            <Grid item xs={12} sm={12} md={12} sx={{ pt: '15px' }}>
              <TableComponent tableRows={tableRows} removeItemFromRows={removeItemFromRows} />
            </Grid>
          )}
        </Grid>
      )}
    </CustomTabPanel>
  );
};

const propTypes = {};

interface ExtraProps {
  t: (par1: string, par2?: TLanguagesOptions) => string;
  tabValue: number;
  values: FormikValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormikValues>>;
  deselectItem: (type: string) => void;
  groupList: Array<Users> | null | undefined;
  errors: FormikErrors<FormikValues>;
  addToTable: () => void;
  tableRows: any[];
  removeItemFromRows: (item: any) => void;
  usersData: any;
  isLoading: boolean;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
AddresseeTab.propTypes = propTypes;

export default AddresseeTab;
