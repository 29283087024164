import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';
import BannerModal from './banner-modal/BannerModal';
import BannerDetail from './banner-detail/BannerDetail';
import CustomLoader from '@/src/components/custom-loader';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import { getBanners } from '@/src/api/endpoints/banners';
import { BoxHeaderInputs, BoxNews, NewsHeader, NewsPagseStylesDefault } from './styles/BannerPage.styled';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';
import BannerCard from './banner-card/BannerCard';
import RefreshButton from '@/src/components/refresh-button/RefreshButton';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import { Banners } from './types';
import SearchInput from '@/src/components/search-input';
import PublicationsFilter from '@/src/components/publications-filter';

export const BannersPage = () => {
  const queryClient = useQueryClient();
  const abortCont = new AbortController();

  const getArticlesData = async () => {
    const response = await getBanners();
    return response.data;
  };

  const { data, isLoading, refetch } = useQuery(['banners'], getArticlesData, {
    refetchOnReconnect: false,
  });

  const [openModal, setOpenModal] = useState(false);
  const [postData, setPostData] = useState<any>(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { SnackBar, setSnackBarMessage } = useSnackBar();
  const { t } = useTranslation('banners');
  const resource = 'banners';
  const isMobile = window.innerWidth < 955;

  const [idSelected, setIdSelected] = useState<number | null>(null);

  useEffect(() => {
    if (data && data.banners?.length > 0) {
      const orderedData = data.banners.sort((a: Banners, b: Banners) => b.start_date - a.start_date);
      setPostData(orderedData);
    } else {
      setPostData([]);
    }
  }, [data]);

  useEffect(() => {
    if (refreshGrid) {
      queryClient.resetQueries(['banners']);
      refetch();
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  useEffect(() => {
    queryClient.resetQueries(['banners']);
    abortCont.abort();
  }, []);

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleClickFiltersModal = () => {
    setOpenFilter(true);
  };

  return (
    <Box sx={!isLoading && postData?.length < 1 ? NewsPagseStylesDefault : { height: 'auto' }}>
      <PublicationsFilter
        resource={resource}
        openModal={openFilter}
        setOpenModal={setOpenFilter}
        endpoint={getBanners}
        setPostData={setPostData}
      />
      <NewsHeader pr={idSelected ? '20px' : '10px'}>
        <BoxNews>
          <Typography variant="h5" component="h1">
            {t('Banners')}
          </Typography>
          <RefreshButton refresh={setRefreshGrid} disabled={isLoading} />
        </BoxNews>
        <BoxHeaderInputs>
          <SearchInput endpoint={getBanners} disabled={false} resource={resource} setPostData={setPostData} />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            size="small"
            color="primary"
            sx={{ width: { sm: 'auto', xs: 'auto' } }}
            onClick={handleClickFiltersModal}
          >
            {t('filter', { ns: 'common' })}
          </Button>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            size="small"
            color="primary"
            sx={{ width: { sm: 'auto', xs: 'auto' } }}
            onClick={handleClick}
          >
            {t('new_publication')}
          </Button>
        </BoxHeaderInputs>
      </NewsHeader>
      <Grid container justifyContent="center">
        <Grid
          rowGap="20px"
          container
          item
          lg={idSelected ? 4 : 12}
          md={idSelected ? 4 : 12}
          sx={{
            maxHeight: `${isMobile ? 'auto' : '100%'}`,
            height: `${idSelected ? '850px' : '100%'}`,
            display: `${isMobile && idSelected && 'none'}`,
            overflow: 'auto',
            width: '100%',
          }}
        >
          {isLoading ? (
            <LoaderContainer>
              <CustomLoader />
            </LoaderContainer>
          ) : (
            ((!idSelected && isMobile) || !isMobile) &&
            postData?.map((item: Banners) => (
              <Grid
                key={item.id}
                item
                lg={idSelected ? 12 : 4}
                md={idSelected ? 12 : 6}
                sm={12}
                xs={12}
                sx={{
                  paddingRight: '10px',
                }}
              >
                <BannerCard item={item} setIdSelected={setIdSelected} />
              </Grid>
            ))
          )}
          {!isLoading && (!postData || postData.length < 1) && (
            <Box sx={{ margin: 'auto' }}>
              <AlertNoData title={t('no_data_was_found', { ns: 'common' })} />
            </Box>
          )}
        </Grid>
        {idSelected && (
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <BannerDetail
              bannerData={data?.banners?.find((ban: any) => ban?.id === idSelected)}
              idSelected={idSelected}
              setIdSelected={setIdSelected}
              setSnackBarMessage={setSnackBarMessage}
              setRefreshGrid={setRefreshGrid}
              resource={resource}
            />
          </Grid>
        )}
      </Grid>
      <BannerModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setSnackBarMessage={setSnackBarMessage}
        setRefreshGrid={setRefreshGrid}
      />
      <SnackBar />
    </Box>
  );
};
