import { ConstructionFormInformationContainerProps } from '../../../types';
import ConstructionForm from './ConstructionFormInformation';
import useImageUploader from '@/src/components/image-uploader/useImageUploader';

const ConstructionFormInformationContainer = ({
  handleSubmit,
  values,
  setFieldError,
  errors,
  errorMessage,
  setFieldValue,
  setDisableButton,
  t,
  tabValue,
}: ConstructionFormInformationContainerProps) => {
  const { ImageUploader, wrongImages: wrongCoverImages } = useImageUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'cover_image_url',
    setFieldValue,
    setDisableForm: setDisableButton,
  });
  const { ImageUploader: AttachedImagesUploader, wrongImages: wrongAttachedImages } = useImageUploader({
    htmlFor: 'attached-images-urls',
    values,
    valueName: 'publication_image_urls',
    setFieldValue,
    setDisableForm: setDisableButton,
  });

  const deleteImage = (value: any, url?: string) => {
    setFieldValue(
      value,
      Array.isArray(values[`${value}`]) ? values?.[`${value}`].filter((element: any) => element.image_url !== url) : '',
    );
  };

  const attachedImagesLength = values?.publication_image_urls.length;

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    setFieldError,
    errors,
    errorMessage,
    setDisableButton,
    t,
    ImageUploader,
    AttachedImagesUploader,
    wrongCoverImages,
    wrongAttachedImages,
    tabValue,
    deleteImage,
    attachedImagesLength,
  };

  return <ConstructionForm {...childProps} />;
};

export default ConstructionFormInformationContainer;
