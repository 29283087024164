import EditIcon from '@mui/icons-material/Edit';
import { Box, Card, CardContent, CardHeader, IconButton, Tooltip, Typography } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import { getMonthDayYearValue } from '@/src/utilities/helpers/dateParser';
import { FadeIn } from '@/src/components/animations';
import DeleteDialog from '@/src/components/dialogs/DeleteDialog';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import { CardAvatar } from '../banner-card/BannerCard.styles';
import { getTenant } from '@/src/utilities/helpers/utils';
import { deleteBanner } from '@/src/api/endpoints/banners';
import BannerModal from '../banner-modal/BannerModal';

const BannerDetail = ({ bannerData, idSelected, setIdSelected, setSnackBarMessage, resource, setRefreshGrid }: any) => {
  const queryClient = useQueryClient();
  const [openEditInformation, setOpenEditInformation] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { t } = useTranslation('banners');

  const cleanHTML = DOMPurify.sanitize(bannerData?.body, {
    USE_PROFILES: { html: true },
  });

  const onCloseArticleSelected = () => {
    setIdSelected(null);
  };

  const { mutate: deleteMutation, status } = useMutation(deleteBanner, {
    onError: async () => {
      setSnackBarMessage(t('uncatch_error', { ns: 'errors' }), 'error');
    },
    onSuccess: async () => {
      onCloseArticleSelected();
      queryClient.resetQueries(['banners']);
      setRefreshGrid(true);
      setSnackBarMessage(t('successful_publication_deletion_snackbar_text', { ns: 'news' }));
    },
  });

  return (
    <>
      <Box sx={{ paddingRight: '20px', overflow: 'auto', ml: '5px' }}>
        <FadeIn>
          <Card sx={{ borderRadius: '5px' }}>
            <CardHeader
              avatar={<CardAvatar aria-label="card-label">{getTenant()}</CardAvatar>}
              title={bannerData?.name}
              titleTypographyProps={{
                fontWeight: 'bold',
                fontSize: '1.2rem',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              subheader={getMonthDayYearValue(new Date(bannerData?.start_date).toString())}
              action={
                <Box sx={{ width: 'max-content' }}>
                  <Tooltip title={t('edit', { ns: 'cdtModal' })}>
                    <IconButton
                      aria-label="settings"
                      sx={{ color: 'secondary.main' }}
                      onClick={() => setOpenEditInformation(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('deletion_dialog_form_title')}>
                    <IconButton sx={{ color: 'secondary.main' }} onClick={() => setDeleteModal(true)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                  <DeleteDialog
                    title={t('deletion_dialog_form_title')}
                    contentText={t('deleting_dialog_form_content', {
                      resource: t('banners_modal_text'),
                      ns: 'common',
                    })}
                    open={deleteModal}
                    setOpen={setDeleteModal}
                    onDelete={() => deleteMutation(bannerData?.id)}
                    deleteStatus={status}
                  />
                  <Tooltip title={t('close')}>
                    <IconButton onClick={onCloseArticleSelected} aria-label="settings" sx={{ color: 'secondary.main' }}>
                      <ClearRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            />
            {bannerData?.image_url && (
              <CustomCardMedia src={bannerData?.image_url ?? ''} alt="cover-image" height="250px" />
            )}
            <CardContent>
              <Typography
                variant="subtitle1"
                sx={{ padding: '8px 0 ', fontSize: '1.10rem', color: 'rgba(0, 0, 0, 0.6)' }}
              >
                {bannerData?.description}
              </Typography>
              {parse(cleanHTML)}
              {/* <RenderNewsImages data={[dataById?.banner.image_url] as NewsImage} /> */}
            </CardContent>
          </Card>
        </FadeIn>
      </Box>
      {openEditInformation && (
        <BannerModal
          bannerData={bannerData}
          openModal={openEditInformation}
          setOpenModal={setOpenEditInformation}
          setSnackBarMessage={setSnackBarMessage}
          setRefreshGrid={setRefreshGrid}
        />
      )}
    </>
  );
};

export default BannerDetail;
