import headLogo from './icons/Icon_black.png';
import mainLogo from './logos/logoHuergo_black.png';
import whiteLogo from './logos/logoHuergo_White.png';
import LoginBackground from './images/LoginBackground.jpg';

export const huergo = {
  headLogo,
  mainLogo,
  whiteLogo,
  backgroundPic: LoginBackground,
};
