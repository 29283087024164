import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { ResidentsDetailSection } from './ResidentsDetailSection';
import { ResidentDetail, ResidentDetailResponse, ResidentsDetailContainerProps } from './types';
import { personSelected } from '@/src/api/endpoints/residents';
import { useColumns } from './useColumns';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';

const ResidentsDetailContainer = (props: ResidentsDetailContainerProps) => {
  const { t } = useTranslation();
  const { selectedRow } = props;

  const {
    columnsPets,
    columnsRelatives,
    columnsVehicles,
    actionColumnsVehicles,
    actionColumnsPets,
    actionColumnsRelatives,
    vehicleHeaderActions,
    headerActionsPets,
    headerActionsRelatives,
  } = useColumns(selectedRow);

  const { SnackBar, setSnackBarMessage } = useSnackBar();

  const queryClient = useQueryClient();

  React.useEffect(
    () => () => {
      queryClient.resetQueries([`personSelected_${selectedRow.id}`]);
    },
    [],
  );

  const {
    data: resident,
    isLoading,
    refetch,
  } = useQuery([`personSelected_${selectedRow.id}`], () => personSelected(selectedRow.id), {
    select: ({ data }) => {
      const propertyResident: ResidentDetailResponse = data?.property_resident;
      const res = {
        id: propertyResident?.person?.id,
        email: propertyResident?.person?.email,
        name: propertyResident?.person?.name,
        alias: propertyResident?.person?.alias,
        surname: propertyResident?.person?.surname,
        birthday: propertyResident?.person?.birthday,
        countryCode: propertyResident?.person?.country_code,
        countryIsoCode: propertyResident?.person?.country_iso_code,
        phoneNumber: propertyResident?.person?.phone_number,
        isLeaseSigner: propertyResident?.person?.is_lease_signer,
        user: propertyResident?.person?.user,
        city: { id: propertyResident?.person?.city?.id, name: propertyResident?.person?.city?.name },
        state: { id: propertyResident?.person?.city?.state?.id, name: propertyResident?.person?.city?.state?.name },
        country: {
          id: propertyResident?.person?.city?.state?.country?.id,
          name: propertyResident?.person?.city?.state?.country?.name,
        },
        identificationFileUrl: propertyResident?.person?.identification_file_url,
        identificationNumber: propertyResident?.person?.identification_number,
        apartmentNumber: propertyResident?.person?.apartment_number,
        floorNumber: propertyResident?.person?.floor_number,
        streetNumber: propertyResident?.person?.street_number,
        streetName: propertyResident?.person?.street_name,
        zipCode: propertyResident.person?.zip_code,
        residencyId: propertyResident?.id, // verificar si usar ese o el que esta en residency
        from: propertyResident?.residency.from,
        to: propertyResident?.residency.to,
      } as ResidentDetail;
      return res;
    },
  });

  const customRefetch = () => {
    queryClient.resetQueries([`personSelected_${selectedRow.id}`]);
    refetch();
  };

  const childProps = {
    ...props,
    resident,
    isLoading,
    t,
    refetch: customRefetch,
    columnsPets,
    columnsRelatives,
    columnsVehicles,
    actionColumnsVehicles,
    actionColumnsPets,
    actionColumnsRelatives,
    vehicleHeaderActions,
    headerActionsPets,
    headerActionsRelatives,
    SnackBar,
    setSnackBarMessage,
  };

  return <ResidentsDetailSection {...childProps} />;
};

export default ResidentsDetailContainer;
