import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MarketingForm from './MarketingForm';
import {
  createMarketingMaterial,
  editMarketingMaterial,
  getMarketingMaterials,
} from '@/src/api/endpoints/marketingMaterials';
import { tRequiredFieldError } from '@/src/utilities/helpers/appConstants';
import { ArticleBody } from '../../marketing-materials-living/types';

const MarketingFormContainer = ({ dataById, close, refetchDataById, setSnackBarMessage, groupName }: any) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [tableRows, setTableRows] = React.useState<any[]>([]);

  const actualDate = new Date().getTime();

  const getMarketingMaterialsData = async () => {
    const response = await getMarketingMaterials(groupName);
    return response.data;
  };
  const { refetch } = useQuery(['publications'], getMarketingMaterialsData);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('marketingMaterials');
  const { mutate } = useMutation(createMarketingMaterial);
  const { mutate: mutateEdit } = useMutation(editMarketingMaterial);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const getInitialValues = () => {
    const hasUsersList = dataById?.targets?.roles.length > 0 || dataById?.targets?.users.length > 0;
    const usersList = dataById?.targets?.roles.length > 0 ? dataById?.targets?.roles : dataById?.targets?.users;
    const userType = dataById?.targets?.roles.length > 0 ? 'group' : 'user';

    return {
      title: dataById?.title ?? '',
      subtitle: dataById?.subtitle ?? '',
      start_date: dataById?.date ?? actualDate,
      document: dataById?.document_url ?? null,
      video: dataById?.video_url ?? null,
      video_url_64: null,
      cover_image_url: dataById?.cover_image_url ?? '',
      body: dataById?.body ?? '',
      publication_image_urls: dataById?.publication_images ?? [],
      isEdit: !!dataById,
      editId: dataById?.id ?? null,
      userSelectedList: hasUsersList ? usersList : [],
      userListTargetType: hasUsersList ? userType : 'group',
    };
  };

  const getValidationSchema = () =>
    Yup.lazy(() =>
      Yup.object().shape({
        title: Yup.string().required(tRequiredFieldError),
        subtitle: Yup.string().required(tRequiredFieldError),
        body: Yup.string().required(tRequiredFieldError),
        cover_image_url: Yup.string().required(tRequiredFieldError),
      }),
    );

  const onSubmit = (data: any) => {
    setIsLoading(true);
    const imageUrls: any = [];
    data.publication_image_urls.map((imageUrl: { image_url: string }) => imageUrls.push(imageUrl.image_url));

    const rolesList: string[] =
      data?.userListTargetType === 'group' ? tableRows?.map((rol: { value: string }) => rol?.value) : [];
    const usersList: number[] =
      data?.userListTargetType !== 'group' ? tableRows?.map((user: { id: number }) => user?.id) : [];

    const body: ArticleBody = {
      title: data.title,
      subtitle: data.subtitle,
      group_name: groupName || '',
      start_date: data.start_date,
      document_url: data.document,
      video_url: data.video || '',
      cover_image_url: data.cover_image_url,
      body: data.body,
      publication_image_urls: imageUrls,
      targets: { roles: rolesList, users: usersList },
    };

    if (!data.isEdit) {
      mutate(body, {
        onSuccess: async () => {
          refetch();
          setSnackBarMessage(t('successful_publication_creation_snackbar_text'));
          close();
        },
        onError: async () => {
          setErrorMessage(t('failed_publication_creation_snackbar_text'));
        },
        onSettled: async () => {
          setIsLoading(false);
        },
      });
    } else {
      mutateEdit(
        { id: dataById.id, body },
        {
          onSuccess: async () => {
            setIsLoading(false);
            refetch();
            refetchDataById(dataById.id);
            setSnackBarMessage(t('successful_publication_edition_snackbar_text'));
            close();
          },
          onError: async () => {
            setIsLoading(false);
            setErrorMessage(t('failed_publication_edition_snackbar_text'));
          },
        },
      );
    }
  };

  const { handleSubmit, values, setFieldValue, errors, setFieldError } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: getValidationSchema(),
  });

  const childProps = {
    handleSubmit,
    values,
    setFieldValue,
    setFieldError,
    errors,
    isLoadingSubmit: isLoading,
    errorMessage,
    disableButton,
    setDisableButton,
    t,
    handleChange,
    tabValue,
    a11yProps,
    tableRows,
    setTableRows,
  };

  return <MarketingForm {...childProps} />;
};

export default MarketingFormContainer;
